(function(LIVEREZ, window, $, undefined) {
  var SearchResults = {};

  // set up empty object to be used for caching data, settings while on the page
  SearchResults.cacheObj = {};

  SearchResults.cacheObj.mapData = {
    markerObjs: [],
    markersArr: [],
    isOpen: false,
    instance: null,
  };

  SearchResults.setSearch = function(SearchObj) {
    this.cacheObj.SearchObj = SearchObj;
    return this;
  };

  SearchResults.searchOrder = function(OrderBy) {
    this.cacheObj.SearchObj.Sort = OrderBy;
    this.cacheObj.SearchObj.currentPage = 1;
    return this;
  };

  SearchResults.searchRun = function() {
    LIVEREZ.Publisher.publish(LIVEREZ.events.searchStart);

    var _this = this;

    // if we already have an ajax request, abort it and use the new one
    if (_this.xhr) {
      _this.xhr.abort();
      _this.xhr = null;
    }

    var _sendData = {
      Beds: _this.cacheObj.SearchObj.Bedrooms,
      sleeps: _this.cacheObj.SearchObj.Guests,
      arrivaldate: _this.cacheObj.SearchObj.ArrivalDate,
      departuredate: _this.cacheObj.SearchObj.DepartureDate,
      comparesearch: _this.cacheObj.SearchObj.CompareSearch,
      style: _this.cacheObj.SearchObj.Style,
      categoryid: Array.isArray(_this.cacheObj.SearchObj.CategoryID)
        ? _this.cacheObj.SearchObj.CategoryID.join(',')
        : _this.cacheObj.SearchObj.CategoryID,
      destinationID: Array.isArray(_this.cacheObj.SearchObj.DestinationID)
        ? _this.cacheObj.SearchObj.DestinationID.join(',')
        : _this.cacheObj.SearchObj.DestinationID,
      communityid: Array.isArray(_this.cacheObj.SearchObj.CommunityID)
        ? _this.cacheObj.SearchObj.CommunityID.join(',')
        : _this.cacheObj.SearchObj.CommunityID,
      admincustdataid: _this.cacheObj.SearchObj.AdminCustDataID,
      dynsiteid: _this.cacheObj.SearchObj.DynsiteID,
      searchtype: _this.cacheObj.SearchObj.SearchType,
      searchOrigin: _this.cacheObj.SearchObj.SearchOrigin,
      advs: _this.cacheObj.SearchObj.AdvancedSearch,
      pagedataid: _this.cacheObj.SearchObj.pagedataid,
      pageSize: _this.cacheObj.SearchObj.pageSize,
      currentPage: _this.cacheObj.SearchObj.currentPage,
      flexibleDates: _this.cacheObj.SearchObj.flexibleDates,
      searchAmenities: _this.cacheObj.SearchObj.searchAmenities,
      showOnlySpecials: _this.cacheObj.SearchObj.showOnlySpecials,
      flexibleDatesThreshold: _this.cacheObj.SearchObj.flexibleDatesThreshold,
    };

    // console.log(_sendData);

    if (_this.cacheObj.SearchObj.Sort > 0) {
      _sendData.O = this.cacheObj.SearchObj.Sort;
    }

    _this.xhr = $.ajax({
      type: 'get',
      url:
        LIVEREZ.DynsiteFunctions.getServerName() + '/inc/api/webservices.aspx?method=searchresults',
      data: _sendData,
      dataType: 'json',
      success: function(data, status, error) {
        data.comparesearch = _sendData.comparesearch;
        LIVEREZ.Publisher.publish(LIVEREZ.events.searchComplete, data);
      },
      error: function(data, status, error) {
        if (!status === 'abort') {
          LIVEREZ.Publisher.publish(LIVEREZ.events.searchComplete, { error: error });
        }
      },
    });
  };

  SearchResults.getCalendarSuggestions = function(preferences) {
    var _searchProperties = [];

    if (
      preferences.hasOwnProperty('properties') &&
      Array.isArray(preferences.properties) &&
      preferences.properties.length
    ) {
      _searchProperties = preferences.properties.map(function(property) {
        return property.pageDataID;
      });
    }

    LIVEREZ.Publisher.publish(LIVEREZ.events.searchSuggestionsStart);

    var _this = this;

    // if we already have an ajax request, abort it and use the new one
    if (_this.csxhr) {
      _this.csxhr.abort();
      _this.csxhr = null;
    }

    var searchedDate = new Date(_this.cacheObj.SearchObj.ArrivalDate);
    var searchEnd = new Date(_this.cacheObj.SearchObj.DepartureDate);

    var _daysSearched = (searchEnd - searchedDate) / (1000 * 60 * 60 * 24);

    _this.daysBack = 5;
    _this.daysForward = _daysSearched > 15 ? _daysSearched + 5 : 15;

    var startDate = LIVEREZ.DynsiteFunctions.dateObjectToString(
      new Date(new Date(searchedDate).setDate(searchedDate.getDate() - _this.daysBack))
    );
    var stopDate = LIVEREZ.DynsiteFunctions.dateObjectToString(
      new Date(new Date(searchedDate).setDate(searchedDate.getDate() + _this.daysForward))
    );

    var consecutiveDays = Math.floor(_daysSearched * 0.8);

    if (consecutiveDays < 2) {
      consecutiveDays = 2;
    }

    var _sendData = {
      bedrooms: _this.cacheObj.SearchObj.Bedrooms,
      guests: _this.cacheObj.SearchObj.Guests,
      startDate: startDate,
      endDate: stopDate,
      categoryid: Array.isArray(_this.cacheObj.SearchObj.CategoryID)
        ? _this.cacheObj.SearchObj.CategoryID.join(',')
        : _this.cacheObj.SearchObj.CategoryID,
      destinationID: Array.isArray(_this.cacheObj.SearchObj.DestinationID)
        ? _this.cacheObj.SearchObj.DestinationID.join(',')
        : _this.cacheObj.SearchObj.DestinationID,
      communityid: Array.isArray(_this.cacheObj.SearchObj.CommunityID)
        ? _this.cacheObj.SearchObj.CommunityID.join(',')
        : _this.cacheObj.SearchObj.CommunityID,
      admincustdataid: _this.cacheObj.SearchObj.AdminCustDataID,
      dynsiteid: _this.cacheObj.SearchObj.DynsiteID,
      consecutiveDays: consecutiveDays,
      maxProperties: 3,
      searchOrigin: _this.cacheObj.SearchObj.SearchOrigin,
    };

    _this.csxhr = $.ajax({
      type: 'get',
      url:
        LIVEREZ.DynsiteFunctions.getServerName() +
        '/inc/api/webservices.aspx?method=calendarsuggestions',
      data: _sendData,
      dataType: 'json',
      success: function(data, status, error) {
        var finalProps = [];

        if (data.length) {
          finalProps = data.filter(function(property) {
            return _searchProperties.indexOf(property.PageDataID) === -1;
          });
        }

        LIVEREZ.Publisher.publish(LIVEREZ.events.searchSuggestionsComplete, {
          preferences: preferences,
          data: finalProps,
        });
      },
      error: function(data, status, error) {
        if (!status === 'abort') {
          LIVEREZ.Publisher.publish(LIVEREZ.events.searchSuggestionsComplete, { error: error });
        } else {
          LIVEREZ.Publisher.publish(LIVEREZ.events.searchSuggestionsComplete, {
            error: 'error fetching results',
          });
        }
      },
    });
  };

  SearchResults.CompareProperties = function() {
    var _this = this;
    if (_this.CheckCompare()) {
      document.Search_xml.PageDataID.value = _this.CompareCheckboxArr.join(',');
      document.Search_xml.submit();
    }
  };

  SearchResults.CheckCompare = function() {
    var _this = this;
    _this.CompareCheckboxArr = [];

    $('input:checkbox[name=PageDataID]:checked').each(function() {
      _this.CompareCheckboxArr.push($(this).val());
    });

    if (this.CompareCheckboxArr.length == 0) {
      alert('No Properties were selected. Please select up to 3 properties to compare.');
      return false;
    } else if (this.CompareCheckboxArr.length == 1) {
      alert('You must select more than 1 property to compare homes.');
      return false;
    } else if (this.CompareCheckboxArr.length > 3) {
      alert('To compare homes, You can only select 3 homes at a time.');
      return false;
    }

    return true;
  };

  SearchResults.setView = function(strView) {
    LIVEREZ.Storage.set('SearchResultsView', strView);
    return this;
  };

  SearchResults.getView = function(strView) {
    return LIVEREZ.Storage.get('SearchResultsView');
  };

  SearchResults.saveResultsData = function(resultsData) {
    this.cacheObj.results = resultsData;
    return this;
  };

  SearchResults.renderResultsbyView = function(callback, addtPropAvailMsgLocation) {
    if (this.cacheObj.results) {
      var data = this.cacheObj.results;
      var _this = this;
      var errorMsg = '';
      var propertiesDiv = $('<div class="search-results-properties-container" />');

      _this.cacheObj.mapData.markerObjs.length = 0;

      if (data.properties && data.properties.length) {
        var propertiesStr = '';

        if (_this.getView() === 'grid') {
          propertiesStr +=
            '<div class="row property-list-wrapper grid-view"><ul class="row-same-height row-full-height list-unstyled">';
        }

        data.properties.forEach(function(propData, index) {
          if (_this.getView() === 'grid') {
            if (index > 0 && index % 3 === 0) {
              propertiesStr +=
                '</ul></div><div class="row property-list-wrapper grid-view"><ul class="row-same-height row-full-height list-unstyled">';
            }

            propertiesStr += _this.SearchResultsTemplateGrid(data, propData);
          } else {
            propertiesStr += _this.SearchResultsTemplateList(data, propData);
          }

          // add the property data to the markers object array for use later signifying this property has a lat and long
          if (propData.latitude && propData.longitude) {
            propData.currencySymbol = propData.currencySymbol || data.currencySymbol;
            propData.currency = propData.currency || data.currency;
            _this.cacheObj.mapData.markerObjs.push(propData);
          }
        });

        if (_this.getView() === 'grid') {
          propertiesStr += '</ul></div>';
        }

        propertiesDiv.append(propertiesStr);

        // create a map button for users to click
        if (_this.cacheObj.mapData.markerObjs.length > 0) {
          LIVEREZ.Publisher.publish(LIVEREZ.events.searchCreateMapImage, {
            lat: _this.cacheObj.mapData.markerObjs[0].latitude,
            lng: _this.cacheObj.mapData.markerObjs[0].longitude,
          });
        }
      } else {
        if (data.returnMessageText) {
          errorMsg += data.returnMessageText;
        } else {
          errorMsg +=
            'We couldn’t find any results that matched your criteria.<br>Try adjusting your dates or removing some search filters to show more properties.';
        }
      }

      // create a week to week message if week to week are present
      if (data.weekToWeek && data.weekToWeek.hasProperties) {
        errorMsg += '<div class="week-to-week-message-container">';

        if (data.properties && data.properties.length) {
          errorMsg += '<h4>Additional Properties Available</h4>';
        }

        errorMsg += '<div>' + '</div>';

        errorMsg += '<div class="row">';
        if (data.weekToWeek.prevSuggestions.length) {
          errorMsg += '<div class="col-sm-6">';
          errorMsg += '<div class="w2w-range-heading"><strong>Try Previous Week</strong></div>';
          data.weekToWeek.prevSuggestions.forEach(function(suggestion) {
            //mutation is bad :(
            suggestion.fixedStartDate = LIVEREZ.DynsiteFunctions.fixEuroDatesForAPI(
              suggestion.startDate,
              dateFormatType
            );
            suggestion.fixedEndDate = LIVEREZ.DynsiteFunctions.fixEuroDatesForAPI(
              suggestion.endDate,
              dateFormatType
            );

            errorMsg +=
              '<div><a href="javascript: LIVEREZ.SearchResults.changeDates(\'' +
              suggestion.fixedStartDate +
              "', '" +
              suggestion.fixedEndDate +
              '\'); _SetSearch();">' +
              LIVEREZ.DynsiteFunctions.truncate(suggestion.startingDay, 3) +
              ' ' +
              suggestion.fixedStartDate +
              ' - ' +
              LIVEREZ.DynsiteFunctions.truncate(suggestion.startingDay, 3) +
              ' ' +
              suggestion.fixedEndDate +
              '</a></div>';
          });
          errorMsg += '</div>';
        }

        if (data.weekToWeek.nextSuggestions.length) {
          errorMsg += '<div class="col-sm-6">';
          errorMsg += '<div class="w2w-range-heading"><strong>Try Next Week</strong></div>';
          data.weekToWeek.nextSuggestions.forEach(function(suggestion) {
            //mutation is bad :(
            suggestion.fixedStartDate = LIVEREZ.DynsiteFunctions.fixEuroDatesForAPI(
              suggestion.startDate,
              dateFormatType
            );
            suggestion.fixedEndDate = LIVEREZ.DynsiteFunctions.fixEuroDatesForAPI(
              suggestion.endDate,
              dateFormatType
            );

            errorMsg +=
              '<div><a href="javascript: LIVEREZ.SearchResults.changeDates(\'' +
              suggestion.fixedStartDate +
              "', '" +
              suggestion.fixedEndDate +
              '\'); _SetSearch();">' +
              LIVEREZ.DynsiteFunctions.truncate(suggestion.startingDay, 3) +
              ' ' +
              suggestion.fixedStartDate +
              ' - ' +
              LIVEREZ.DynsiteFunctions.truncate(suggestion.startingDay, 3) +
              ' ' +
              suggestion.fixedEndDate +
              '</a></div>';
          });
          errorMsg += '</div>';
        }

        errorMsg += '</div>';
        errorMsg += '</div>';
      } else if (
        data.minimumNightsStay &&
        data.minimumNightsStay.hasProperties &&
        data.minimumNightsStay.showMinNightsStayMessage !== false
      ) {
        if (data.properties && data.properties.length) {
          errorMsg += '<h4>Additional Properties Available</h4>';
        }

        errorMsg +=
          '<div style="margin-top: 10px">' + '</div>';
      }

      // if error message exists, add it to the results
      if (errorMsg !== '') {
        if (addtPropAvailMsgLocation === 'top') {
          propertiesDiv.prepend('<div class="search-results-error-message">' + errorMsg + '</div>');
        } else {
          propertiesDiv.append('<div class="search-results-error-message">' + errorMsg + '</div>');
        }
      }

      try {
        setTimeout(function() {
          LIVEREZ.Publisher.publish(LIVEREZ.events.resultsRenderComplete);
        });
      } catch (error) {
        console.log(error);
      }

      callback(propertiesDiv);
    }
    // no data stored, go get some data
    else {
      SearchResults.searchRun();
    }
  };

  SearchResults.createMap = function(mapType) {
    var _this = this;
    var markers = this.cacheObj.mapData.markerObjs;

    $('.search-results-map-view-header-span').text(
      'Viewing Map for ' + markers.length + ' Vacation Rentals'
    );

    if (mapType === LIVEREZ.constants.MAP_TYPE.google) {
      var mapOptions = {
        zoom: 10,
        center: new google.maps.LatLng(markers[0].latitude, markers[0].longitude),
        styles: [
          {
            featureType: 'poi.business',
            elementType: 'labels',
            stylers: [{ visibility: 'off' }],
          },
        ],
      };

      var map = new google.maps.Map(document.getElementById('map_canvas'), mapOptions);

      _this.cacheObj.mapData.instance = map;

      // create a single infowindow so that only 1 can be open at a time
      this.infowindow = new google.maps.InfoWindow();
    } else if (mapType === LIVEREZ.constants.MAP_TYPE.leaflet) {
      if (_this.cacheObj.mapData.instance) {
        _this.cacheObj.mapData.instance.off();
        _this.cacheObj.mapData.instance.remove();
      }

      var mapOptions = {
        scrollWheelZoom: false,
      };

      var map = L.map('map_canvas', mapOptions);

      _this.cacheObj.mapData.instance = map;

      //delete all the current markers from the map before we proceed to create / add new ones
      _this.deleteAllMapMarkers(mapType);

      if (Array.isArray(markers) && markers.length) {
        map.setView([markers[0].latitude, markers[0].longitude], 10);
      } else {
        map.setView([43.68131, -116.362402], 10);
      }

      L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        attribution:
          '&copy; <a href="https://openstreetmap.org/copyright">OpenStreetMap</a> contributors',
      }).addTo(map);
    }

    this.createMapMarkers(map, markers, mapType);
  };

  SearchResults.deleteAllMapMarkers = function(mapType) {
    var _this = this;

    for (var i = 0; i < this.cacheObj.mapData.markersArr.length; i++) {
      if (mapType === LIVEREZ.constants.MAP_TYPE.google) {
        _this.cacheObj.mapData.markersArr[i].setMap(null);
      } else if (mapType === LIVEREZ.constants.MAP_TYPE.leaflet) {
        if (_this.cacheObj.mapData.instance) {
          _this.cacheObj.mapData.instance.removeLayer(_this.cacheObj.mapData.markersArr[i]);
        }
      }
    }

    this.cacheObj.mapData.markersArr.length = 0;
  };

  SearchResults.createMapMarkers = function(map, markers, mapType) {
    var _this = this;

    if (Array.isArray(markers) && markers.length) {
      if (mapType === LIVEREZ.constants.MAP_TYPE.google) {
        var bounds = new google.maps.LatLngBounds();

        markers.forEach(function(marker) {
          var infoWindowHTML = LIVEREZ.SearchResults.SearchResultsTemplateMapInfoWindow(marker);
          var markerLatLng = new google.maps.LatLng(marker.latitude, marker.longitude);

          var marker = new google.maps.Marker({
            position: markerLatLng,
            map: map,
            title: marker.pid,
          });

          // put the marker in an array so we can remove it from the map later if the results change
          _this.cacheObj.mapData.markersArr.push(marker);

          google.maps.event.addListener(marker, 'click', function() {
            _this.infowindow.setContent(infoWindowHTML);
            _this.infowindow.open(map, this);
          });

          // extend the map bounds with this markers position
          bounds.extend(markerLatLng);
        });

        // set the map to fit the bounds
        map.fitBounds(bounds);
      } else if (mapType === LIVEREZ.constants.MAP_TYPE.leaflet) {
        var featureGroup = L.featureGroup().addTo(map);

        markers.forEach(function(marker) {
          var infoWindowHTML = LIVEREZ.SearchResults.SearchResultsTemplateMapInfoWindow(marker);
          L.marker([marker.latitude, marker.longitude])
            .addTo(featureGroup)
            .bindPopup(infoWindowHTML);

          // put the marker in an array so we can remove it from the map later if the results change
          _this.cacheObj.mapData.markersArr.push(marker);
        });

        featureGroup.addTo(map);
        map.fitBounds(featureGroup.getBounds());
      }
    }
  };

  SearchResults.openMap = function(mapType) {
    LIVEREZ.Publisher.publish(LIVEREZ.events.searchMapOpen);
    this.cacheObj.mapData.isOpen = true;
    this.createMap(mapType);
  };

  SearchResults.closeMap = function() {
    LIVEREZ.Publisher.publish(LIVEREZ.events.searchMapClose);
    this.cacheObj.mapData.isOpen = false;
  };

  SearchResults.changeDates = function(startD, endD) {
    $('#txtStartDate').val(startD);
    $('#txtStartDate-mobile').val(startD);
    $('#AD').val(startD);

    $('#txtEndDate').val(endD);
    $('#txtEndDate-mobile').val(endD);
    $('#DD').val(endD);

    $('#FlexibleDatesThreshold').val('');

    window.scrollTo(0, 0);

    //$('.start-date').val(startD);
    //$('.end-date').val(endD);

    return this;
  };

  SearchResults.renderAmenities = function(amenities, cb) {
    var str = '';

    for (var key in amenities) {
      var list = amenities[key].filter(function(item) {
        return (item.hasOwnProperty('count') && item.count > 0) || item.checked;
      });

      if (Array.isArray(list) && list.length) {
        list.forEach(function(amenity, i) {
          var item = $('<div/>').addClass(
            'search-amenities-item checkbox secondary-advanced-checkbox'
          );

          var check = $('<input />')
            .attr('type', 'checkbox')
            .attr('id', 'search-amenitiy-' + amenity.amenityID)
            .attr('name', 'searchAmenities')
            .attr('checked', amenity.checked)
            .val(amenity.amenityID);

          var label = $('<label />')
            .attr('style', 'text-transform: capitalize; padding-left: 30px;')
            .attr('for', 'search-amenitiy-' + amenity.amenityID);

          label.append(check).append(amenity.amenity);
          item.append(label);

          str += item.get(0).outerHTML;
        });
      }
    }

    cb(str);

    return this;
  };

  SearchResults.getCheckedAmenityCount = function(amenities, cb) {
    var count = 0;
    for (var key in amenities) {
      var list = amenities[key].filter(function(item) {
        return item.hasOwnProperty('checked') && !!item.checked;
      });
      count += list.length;
    }

    cb(count);

    return this;
  };

  LIVEREZ.SearchResults = SearchResults;
})((window.LIVEREZ = window.LIVEREZ || {}), window, lrjQ);
