"use strict";

function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

function _iterableToArrayLimit(arr, i) { var _i = arr == null ? null : typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"]; if (_i == null) return; var _arr = []; var _n = true; var _d = false; var _s, _e; try { for (_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

(function (LIVEREZ, window, $, undefined) {
  var CALENDAR_DAY_TYPES = {
    Available: 0,
    Unavailable: 1,
    CheckIn: 2,
    CheckOut: 3,
    EarlyCheckIn: 4,
    LateCheckOut: 5,
    EarlyCheckInLateCheckOut: 6,
    SingleNightRental: 7,
    TurnOver: 8,
    TurnOverEarlyCheckIn: 9,
    TurnOverLateCheckOut: 10
  };

  var CalendarAvailability = function CalendarAvailability(dynsiteId, adminCustDataID, pageDataId) {
    this.dynsiteId = dynsiteId;
    this.adminCustDataId = adminCustDataID;
    this.pageDataId = pageDataId;
    this.unavailableTypes = [CALENDAR_DAY_TYPES.Unavailable, CALENDAR_DAY_TYPES.EarlyCheckIn, CALENDAR_DAY_TYPES.LateCheckOut, CALENDAR_DAY_TYPES.EarlyCheckInLateCheckOut, CALENDAR_DAY_TYPES.SingleNightRental, CALENDAR_DAY_TYPES.TurnOver, CALENDAR_DAY_TYPES.TurnOverEarlyCheckIn, CALENDAR_DAY_TYPES.TurnOverLateCheckOut];
    this.unavailableDateObjs = [];
    this.arrivalDateObjs = [];
    this.departureDateObjs = [];
    this.unavailableDateObjsMobile = [];
    this.queriedYears = [];
  };

  CalendarAvailability.prototype.getCalendarData = function (startDate, endDate, callback) {
    var _this = this;

    if (_this.xhr && _this.xhr.readystate != 4) {
      _this.xhr.abort();

      _this.xhr = null;
    }

    this.queriedYears.push(new Date(startDate).getFullYear());
    _this.xhr = $.ajax({
      type: 'get',
      url: LIVEREZ.DynsiteFunctions.getServerName() + '/inc/api/webservices.aspx?method=propertycalendardata',
      data: {
        AdminCustDataID: _this.adminCustDataId,
        DynSiteID: _this.dynsiteId,
        PageDataID: _this.pageDataId,
        ad: startDate,
        dd: endDate
      },
      dataType: 'json',
      success: function success(data) {
        try {
          data.forEach(function (d) {
            var formattedDate = LIVEREZ.DynsiteFunctions.dateStringToObject(d.startDateString);

            if (dateFormatType === 1) {
              d.startDateString = LIVEREZ.DynsiteFunctions.fixEuroDatesForAPI(d.startDateString, dateFormatType);
            }

            switch (d.calendarDayTypes) {
              case CALENDAR_DAY_TYPES.CheckIn:
                _this.arrivalDateObjs.push(d.startDateString);

                break;

              case CALENDAR_DAY_TYPES.CheckOut:
                _this.departureDateObjs.push(d.startDateString);

                break;

              default:
                _this.unavailableDateObjs.push(d.startDateString);

                _this.unavailableDateObjsMobile.push(formattedDate);

            }
          });
          _this.unavailableDateObjs = _this.unavailableDateObjs.filter(function (element, index, array) {
            return array.indexOf(element) >= index;
          });
          _this.unavailableDateObjsMobile = _this.unavailableDateObjsMobile.filter(function (element, index, array) {
            return array.indexOf(element) >= index;
          });
          callback();
        } catch (ex) {
          console.error(ex);
          callback();
        }
      },
      error: function error(data, status, _error) {
        $('.datepicker-overlay').remove();
        console.log(data);
        console.log(status);
        console.log(_error);
      }
    });
  }; // function isDST(t) { //t is the date object to check, returns true if daylight saving time is in effect.
  // 	var jan = new Date(t.getFullYear(),0,1);
  // 	var jul = new Date(t.getFullYear(),6,1);
  // 	return Math.min(jan.getTimezoneOffset(),jul.getTimezoneOffset()) == t.getTimezoneOffset();
  // }


  CalendarAvailability.prototype.findDateConflicts = function (start, end) {
    var error = false;
    var start = new Date(LIVEREZ.DynsiteFunctions.fixEuroDatesForAPI(start, dateFormatType));
    var end = new Date(LIVEREZ.DynsiteFunctions.fixEuroDatesForAPI(end, dateFormatType));

    for (var i = 0; i < this.unavailableDateObjs.length; i++) {
      var tmpDate = this.unavailableDateObjs[i];

      if (tmpDate >= start && tmpDate <= end) {
        error = true;
      }
    }

    return error;
  };

  LIVEREZ.CalendarAvailability = CalendarAvailability;
})(window.LIVEREZ = window.LIVEREZ || {}, window, lrjQ);

(function (LIVEREZ, window) {
  LIVEREZ.constants = {
    MAP_TYPE: {
      google: 1,
      leaflet: 2
    }
  };
})(window.LIVEREZ = window.LIVEREZ || {}, void 0);
/*
 * @author Joshua Hurst
 * @date 5/27/2014
 *
 * @description A set of functions to be used with LiveRez Dynsites
 */


(function (LIVEREZ, window, $, undefined) {
  var DynsiteFunctions = {};

  DynsiteFunctions.PopUp = function (URL, Window, w, h, x, y) {
    var popupwin = window.open(URL, Window, 'toolbar=no,location=no,directories=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=' + w + ',height=' + h + ',left=' + x + ',top=' + y);
  };

  DynsiteFunctions.socialSharePage = function (socialType, image, headline) {
    var url;
    var page = encodeURIComponent(window.location.href).toString();
    image = encodeURIComponent(image);
    headline = encodeURIComponent(headline);

    switch (socialType) {
      case 'facebook':
        url = 'https://www.facebook.com/sharer.php?u=' + page;
        break;

      case 'twitter':
        url = 'https://twitter.com/intent/tweet?source=tweetbutton&text=' + headline + '&url=' + page;
        break;

      case 'pinterest':
        url = 'https://www.pinterest.com/pin/create/button/?url=' + page + '&media=' + image + '&description=' + headline;
        break;

      case 'googlePlus':
        url = 'https://plus.google.com/share?url=' + page;
        break;
    }

    this.PopUp(url, window, 400, 350);
  };

  DynsiteFunctions.isEmailAddr = function (email) {
    var result = false;
    var theStr = new String(email);
    var index = theStr.indexOf('@');

    if (index > 0) {
      var pindex = theStr.indexOf('.', index);
      if (pindex > index + 1 && theStr.length > pindex + 1) result = true;
    }

    return result;
  };

  DynsiteFunctions.validRequired = function (formField, fieldLabel) {
    var result = true;

    if (formField.value == '') {
      alert('Please enter a value for the "' + fieldLabel + '" field.');
      formField.focus();
      result = false;
    }

    return result;
  };

  DynsiteFunctions.allDigits = function (str) {
    return this.inValidCharSet(str, '0123456789');
  };

  DynsiteFunctions.inValidCharSet = function (str, charset) {
    var result = true; // Doesn't use regular expressions to avoid early Mac browser bugs

    for (var i = 0; i < str.length; i++) {
      if (charset.indexOf(str.substr(i, 1)) < 0) {
        result = false;
        break;
      }
    }

    return result;
  };

  DynsiteFunctions.validEmail = function (formField, fieldLabel, required) {
    var result = true;
    if (required && !this.validRequired(formField, fieldLabel)) result = false;

    if (result && (formField.value.length < 3 || !this.isEmailAddr(formField.value))) {
      alert('Please enter a complete email address in the form: yourname@yourdomain.com');
      formField.focus();
      result = false;
    }

    return result;
  };

  DynsiteFunctions.validNum = function (formField, fieldLabel, required) {
    var result = true;
    if (required && !this.validRequired(formField, fieldLabel)) result = false;

    if (result) {
      if (!this.allDigits(formField.value)) {
        alert('Please enter a number for the "' + fieldLabel + '" field.');
        formField.focus();
        result = false;
      }
    }

    return result;
  };

  DynsiteFunctions.validInt = function (formField, fieldLabel, required) {
    var result = true;
    if (required && !this.validRequired(formField, fieldLabel)) result = false;

    if (result) {
      var num = parseInt(formField.value, 10);

      if (isNaN(num)) {
        alert('Please enter a number for the "' + fieldLabel + '" field.');
        formField.focus();
        result = false;
      }
    }

    return result;
  };

  DynsiteFunctions.validDate = function (formField, fieldLabel, required) {
    var result = true;
    if (required && !this.validRequired(formField, fieldLabel)) result = false;

    if (result) {
      var elems = formField.value.split('/');
      result = elems.length == 3; // should be three components

      if (result) {
        var month = parseInt(elems[0], 10);
        var day = parseInt(elems[1], 10);
        var year = parseInt(elems[2], 10);
        result = allDigits(elems[0]) && month > 0 && month < 13 && allDigits(elems[1]) && day > 0 && day < 32 && allDigits(elems[2]) && (elems[2].length == 2 || elems[2].length == 4);
      }

      if (!result) {
        alert('Please enter a date in the format MM/DD/YYYY for the "' + fieldLabel + '" field.');
        formField.focus();
      }
    }

    return result;
  };

  DynsiteFunctions.getServerName = function () {
    if (!window.location.origin) {
      return window.location.protocol + '//' + window.location.hostname + (window.location.port ? ':' + window.location.port : '');
    } else {
      return window.location.origin;
    }
  };

  DynsiteFunctions.goPropertyByElem = function (selectObj) {
    var val = selectObj.options[selectObj.selectedIndex].value;

    if (val != '') {
      window.parent.location.href = val;
    }
  };

  DynsiteFunctions.goPropertyByID = function (PageDataID) {
    if (PageDataID) {
      window.parent.location.href = '/vacation-rental-home.asp?PageDataID=' + PageDataID;
    }
  };

  DynsiteFunctions.sendtofriend = function (PageDataID) {
    window.parent.location.href = '/vacation-rental-home-stf.asp?PageDataID=' + PageDataID;
  };

  DynsiteFunctions.propertycontact = function (PageDataID, AD, DD) {
    window.parent.location.href = '/vacation-rental-home-contact.asp?PageDataID=' + PageDataID + '&ad=' + AD + '&dd=' + DD;
  };

  DynsiteFunctions.viewproperty = function (strPageDataID, strUnitID) {
    var formObj = document.viewproperty;
    formObj.action = '/vacation-rental-home.asp?PageDataID=' + strPageDataID;
    formObj.PageDataID.value = strPageDataID;
    formObj.WebReferencePageDataID.value = strPageDataID;
    formObj.InventoryUnitDataID.value = strUnitID;
    formObj.submit();
  };

  DynsiteFunctions.debounce = function (func, wait, immediate) {
    var timeout;
    return function () {
      var context = this,
          args = arguments;

      var later = function later() {
        timeout = null;
        if (!immediate) func.apply(context, args);
      };

      var callNow = immediate && !timeout;
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
      if (callNow) func.apply(context, args);
    };
  };

  DynsiteFunctions.DateShort = function (DateStr, delimeter) {
    var tmpDate = new Date(DateStr);
    delimeter = delimeter || '/', tmpDate = tmpDate.getMonth() + 1 + delimeter + tmpDate.getDate() + delimeter + tmpDate.getFullYear();
    return tmpDate;
  };

  DynsiteFunctions.DateNatural = function (d) {
    var includeYear = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;

    if (typeof d === 'string') {
      if (d.indexOf('-') > 0) {
        d = new Date(d);
        d.setHours(d.getHours() + new Date().getTimezoneOffset() / 60);
      } else {
        d = new Date(d);
      }
    }

    var MONTH_NAMES = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    var DAY_NAMES = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

    function nth(d) {
      if (d > 3 && d < 21) return 'th'; // thanks kennebec

      switch (d % 10) {
        case 1:
          return 'st';

        case 2:
          return 'nd';

        case 3:
          return 'rd';

        default:
          return 'th';
      }
    }

    var _final = DAY_NAMES[d.getDay()] + ' ' + MONTH_NAMES[d.getMonth()] + ' ' + d.getDate() + nth(d.getDate());

    if (includeYear) {
      _final += ', ' + d.getFullYear();
    }

    return _final;
  };
  /***** NAVIS 800 Number and Keyword Tracking *****/


  DynsiteFunctions.handleNavisCookies = function (NavisKeyword, NavisPhone) {
    if (NavisKeyword !== '' && NavisPhone !== '') {
      LIVEREZ.Storage.set(DYN_SITE_ID + 'navis800Num', NavisPhone);
      LIVEREZ.Storage.set(DYN_SITE_ID + 'NavisHiddenKeywordVal', NavisKeyword);
      this.fix800NumberForNavis();
    }

    if (LIVEREZ.Storage.get(DYN_SITE_ID + 'navis800Num')) {
      this.fix800NumberForNavis();
    }
  };

  DynsiteFunctions.fix800NumberForNavis = function () {
    // get the cookies
    var NavisHiddenPhoneVal = LIVEREZ.Storage.get(DYN_SITE_ID + 'navis800Num');
    var NavisHiddenKeywordVal = LIVEREZ.Storage.get(DYN_SITE_ID + 'NavisHiddenKeywordVal'); // insert phone number into holder span

    $('.phone-link').attr('href', 'tel: ' + NavisHiddenPhoneVal);
    $('.800PhoneHolder').text(NavisHiddenPhoneVal);
    var NavisHiddenKeyword = $('#NavisHiddenKeyword');

    if (NavisHiddenKeywordVal && NavisHiddenKeyword) {
      NavisHiddenKeyword.val(NavisHiddenKeywordVal);
    }

    var NavisHiddenPhone = $('#NavisCode');

    if (NavisHiddenPhone && NavisHiddenPhoneVal) {
      NavisHiddenPhone.val(NavisHiddenPhoneVal);
    }
  };
  /***** END NAVIS 800 Number and Keyword Tracking *****/

  /***** CRM PLUS LEAD Tracking ************************/


  DynsiteFunctions.handleCRMPlusCookies = function (LeadID) {
    if (LIVEREZ.Storage.get(DYN_SITE_ID + 'LeadID')) {
      this.insertLeadCookie();
    } else {
      if (LeadID !== '') {
        LIVEREZ.Storage.set(DYN_SITE_ID + 'LeadID', LeadID);
        this.insertLeadCookie();
      }
    }
  };

  DynsiteFunctions.insertLeadCookie = function () {
    var leadCookie = LIVEREZ.Storage.get(DYN_SITE_ID + 'LeadID');
    var leadIDInput = $('.hiddenLeadID');

    if (leadCookie && leadIDInput) {
      leadIDInput.val(leadCookie);
    }
  };
  /***** END CRM PLUS LEAD Tracking *******************/


  DynsiteFunctions.trimDate = function (strDate) {
    if (strDate && typeof strDate === 'string') {
      strDate = strDate.replace(/^0+/, '');
      strDate = strDate.replace(/\/0/, '/');
    }

    return strDate;
  };

  DynsiteFunctions.fixEuroDatesForAPI = function (strDate, dateFormat) {
    if (dateFormat === 1) {
      var parts = strDate.split('/');
      return parts[1] + '/' + parts[0] + '/' + parts[2];
    } else {
      return strDate;
    }
  };

  DynsiteFunctions.dateStringToISO8601 = function (dateString) {
    var myDate = dateString.split('/');
    var returnDate = [myDate[2], myDate[0], myDate[1]].join('-');
    return returnDate;
  };

  DynsiteFunctions.dateObjectToString = function (dateObj) {
    return dateObj.getMonth() + 1 + '/' + dateObj.getDate() + '/' + dateObj.getFullYear();
  };

  DynsiteFunctions.dateStringToObject = function (dateStr) {
    var year, month, day;

    if (dateStr.indexOf('-') > -1) {
      var parts = dateStr.split('-');

      if (parts[0].length === 4) {
        year = parts[0];
        month = parts[1];
        day = parts[2];
      } else {
        year = parts[2];
        month = parts[0];
        day = parts[1];
      }
    } else {
      var parts = dateStr.split('/');
      year = parts[2];
      month = parts[0];
      day = parts[1];
    }

    return new Date(year, month - 1, day);
  };

  DynsiteFunctions.truncate = function (string, length, elipsisBool) {
    var _str = string.substring(0, length);

    if (string.length > length && elipsisBool) {
      _str += '...';
    }

    return _str;
  };

  DynsiteFunctions.daydiff = function (first, second) {
    return (second - first) / (1000 * 60 * 60 * 24);
  };

  DynsiteFunctions.checkValidStartEndDates = function (date1, date2) {
    var now = new Date().setHours(0, 0, 0, 0);
    date1 = new Date(date1).setHours(0, 0, 0, 0);
    date2 = new Date(date2).setHours(0, 0, 0, 0);
    var numSearchedDay = this.daydiff(date1, date2);
    var maxDays = maxWebRentalDays || 365;

    if (date1 === date2) {
      return 'Arrival and Departure dates cannot be the same.';
    }

    if (now > date1) {
      return 'Arrival date must be in the future.';
    }

    if (now > date2) {
      return 'Departure date must be in the future.';
    }

    if (date2 <= date1) {
      return 'Departure date must come after arrival date.';
    }

    if (numSearchedDay > maxDays) {
      return 'Travel Dates cannot span more than ' + maxDays + ' days. Please contact us for additional booking options.';
    }
  };

  DynsiteFunctions.isMobileDevice = function () {
    var check = false;

    (function (a) {
      if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(a) || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(a.substr(0, 4))) check = true;
    })(navigator.userAgent || navigator.vendor || window.opera);

    return check;
  };

  DynsiteFunctions.checkForEmailElementToHide = function () {
    var div = document.getElementById('email-div');

    if (div) {
      div.style.display = 'none';
    }
  };

  DynsiteFunctions.fixTextForDisplay = function (strText) {
    if (strText) {
      strText = strText.replace(/`/g, "'");
    }

    return strText;
  };

  LIVEREZ.DynsiteFunctions = DynsiteFunctions;
})(window.LIVEREZ = window.LIVEREZ || {}, window, lrjQ);

$(window).on('load', function () {
  // check for inputs to hide on load
  LIVEREZ.DynsiteFunctions.checkForEmailElementToHide();
});

(function (LIVEREZ, window, $) {
  LIVEREZ.events = {
    searchCreateMapImage: 'search-map-image',
    searchMapOpen: 'search-map-open',
    searchMapClose: 'search-map-close',
    searchStart: 'search-start',
    searchComplete: 'search-complete',
    resultsRenderComplete: 'results-render-complete',
    instantQuoteFinished: 'instant-quote-finished',
    calDataFinished: 'cal-data-finished',
    searchSuggestionsStart: 'search-suggesttions-start',
    searchSuggestionsComplete: 'search-suggesttions-complete',
    calendarSearchStart: 'calendar-search-start',
    calendarSearchComplete: 'calendar-search-complete',
    calendarSearchRenderComplete: 'calendar-search-render-complete',
    propertyDateRangeStart: 'property-date-range-start',
    propertyDateRangeComplete: 'property-date-range-complete',
    propertyDateRangeError: 'property-date-range-error',
    propertyDateRangeSuccess: 'property-date-range-success'
  };
})(window.LIVEREZ = window.LIVEREZ || {}, window, lrjQ);

(function (NAMESPACE, window, $) {
  try {
    $(window.document).ready(function () {
      if (LR_ENV === 'PROD' && window.ga && window.ga.loaded) {
        var page = window.location.pathname;
        var $propertyBookItNowButton = $('div.property-instant-quote-action > a.property-instant-quote-button-book');
        var $propertySidebarContactManagerButton = $('#content2Left > div.row.property-side-contact-manager > div > a');
        var $propertySendToFriendLink = $('a.property-send-to-friend-link');
        var $propertyCommunityLearnMoreLink = $('a.property-community-learn-more-link');
        var $propertyChangeDatesLink = $('#propertyInstantQuote > div.panel-footer > a.property-instant-quote-change-dates');
        var $propertyReviewWidgetReviewsAnchor = $('#property-rating-panel > div > div.lr-info-block-property-rating-property-panel > div.lr-info-block-property-rating-show-reviews > a');
        var $propertyAllPhotosButton = $('#Photos > div.row > div > ul > li > a[data-target="#propGalleryModal"]');
        var $propertyFullScreen = $('#photo-gallery > div.rsOverflow > div.rsFullscreenBtn > div');
        var $propertyShowAllAmenitiesExpand = $('.standard-amenities-section--show-more > a');
        var $searchComparePropertiesButton = $('.search-compare-button');
        var $searchViewMoreCalendars = $('.search-results-calendar-suggestions--view-more');
        var $searchChangeDatesButton = $('.search-update-dates');

        if ($propertyBookItNowButton.length) {
          $propertyBookItNowButton.on('click', function () {
            ga('lrTracker.send', 'event', 'Property', 'click', 'Book It Now Button');
          });
        }

        if ($propertySidebarContactManagerButton.length) {
          $propertySidebarContactManagerButton.on('click', function () {
            ga('lrTracker.send', 'event', 'Property', 'click', 'Sidebar Contact Manager Button');
          });
        }

        if ($propertySendToFriendLink.length) {
          $propertySendToFriendLink.on('click', function () {
            ga('lrTracker.send', 'event', 'Property', 'click', 'Send to Friend Link');
          });
        }

        if ($propertyCommunityLearnMoreLink.length) {
          $propertyCommunityLearnMoreLink.on('click', function () {
            ga('lrTracker.send', 'event', 'Property', 'click', 'Community Learn More Link');
          });
        }

        if ($propertyChangeDatesLink.length) {
          $propertyChangeDatesLink.on('click', function () {
            ga('lrTracker.send', 'event', 'Property', 'click', 'Change Dates Link');
          });
        }

        if ($propertyReviewWidgetReviewsAnchor.length) {
          $propertyReviewWidgetReviewsAnchor.on('click', function () {
            ga('lrTracker.send', 'event', 'Property', 'click', 'Reviews Widget Review Anchor');
          });
        }

        if ($propertyAllPhotosButton.length) {
          $propertyAllPhotosButton.on('click', function () {
            ga('lrTracker.send', 'event', 'Property', 'click', 'All Photos Button');
          });
        }

        if ($propertyFullScreen.length) {
          $propertyFullScreen.on('click', function () {
            ga('lrTracker.send', 'event', 'Property', 'click', 'Slideshow Fullscreen Button');
          });
        }

        if ($searchComparePropertiesButton.length) {
          $searchComparePropertiesButton.on('click', function () {
            ga('lrTracker.send', 'event', 'Search', 'click', 'Compare Properties Button');
          });
        }

        if ($searchViewMoreCalendars.length) {
          $searchViewMoreCalendars.on('click', function () {
            ga('lrTracker.send', 'event', 'Search', 'click', 'View More Property Calendars button');
          });
        }

        if ($propertyShowAllAmenitiesExpand.length) {
          $propertyShowAllAmenitiesExpand.on('click', function () {
            ga('lrTracker.send', 'event', 'Property', 'click', 'Show All Amenities Expand Link');
          });
        } // search page


        if (page === 'vacation-rentals-homes-search.asp') {
          $searchComparePropertiesButton.on('click', function () {
            ga('lrTracker.send', 'event', 'Search', 'click', 'Compare Properties Button');
          });
          $searchViewMoreCalendars.on('click', function () {
            ga('lrTracker.send', 'event', 'Search', 'click', 'View More Property Calendars button');
          });
          $searchChangeDatesButton.on('click', function () {
            ga('lrTracker.send', 'event', 'Search', 'click', 'Change Dates button');
          });
          $(document).on('click', '.search-pagination a', function (e) {
            ga('lrTracker.send', 'event', 'Search', 'click', 'Paging Anchor', $(this).data('page'));
          });
        }
      }
    });
  } catch (error) {
    console.error(error);
  }
})(window.LIVEREZ = window.LIVEREZ || {}, window, lrjQ);

if (!Array.prototype.filter) {
  Array.prototype.filter = function (fun
  /*, thisArg*/
  ) {
    'use strict';

    if (this === void 0 || this === null) {
      throw new TypeError();
    }

    var t = Object(this);
    var len = t.length >>> 0;

    if (typeof fun !== 'function') {
      throw new TypeError();
    }

    var res = [];
    var thisArg = arguments.length >= 2 ? arguments[1] : void 0;

    for (var i = 0; i < len; i++) {
      if (i in t) {
        var val = t[i]; // NOTE: Technically this should Object.defineProperty at
        //       the next index, as push can be affected by
        //       properties on Object.prototype and Array.prototype.
        //       But that method's new, and collisions should be
        //       rare, so use the more-compatible alternative.

        if (fun.call(thisArg, val, i, t)) {
          res.push(val);
        }
      }
    }

    return res;
  };
}

if (!Array.prototype.forEach) {
  Array.prototype.forEach = function (callback, thisArg) {
    var T, k;

    if (this == null) {
      throw new TypeError(' this is null or not defined');
    } // 1. Let O be the result of calling ToObject passing the |this| value as the argument.


    var O = Object(this); // 2. Let lenValue be the result of calling the Get internal method of O with the argument "length".
    // 3. Let len be ToUint32(lenValue).

    var len = O.length >>> 0; // 4. If IsCallable(callback) is false, throw a TypeError exception.
    // See: http://es5.github.com/#x9.11

    if (typeof callback !== 'function') {
      throw new TypeError(callback + ' is not a function');
    } // 5. If thisArg was supplied, let T be thisArg; else let T be undefined.


    if (arguments.length > 1) {
      T = thisArg;
    } // 6. Let k be 0


    k = 0; // 7. Repeat, while k < len

    while (k < len) {
      var kValue; // a. Let Pk be ToString(k).
      //   This is implicit for LHS operands of the in operator
      // b. Let kPresent be the result of calling the HasProperty internal method of O with argument Pk.
      //   This step can be combined with c
      // c. If kPresent is true, then

      if (k in O) {
        // i. Let kValue be the result of calling the Get internal method of O with argument Pk.
        kValue = O[k]; // ii. Call the Call internal method of callback with T as the this value and
        // argument list containing kValue, k, and O.

        callback.call(T, kValue, k, O);
      } // d. Increase k by 1.


      k++;
    } // 8. return undefined

  };
}

if (!Array.prototype.indexOf) {
  Array.prototype.indexOf = function (searchElement, fromIndex) {
    if (this === undefined || this === null) {
      throw new TypeError('"this" is null or not defined');
    }

    var length = this.length >>> 0; // Hack to convert object.length to a UInt32

    fromIndex = +fromIndex || 0;

    if (Math.abs(fromIndex) === Infinity) {
      fromIndex = 0;
    }

    if (fromIndex < 0) {
      fromIndex += length;

      if (fromIndex < 0) {
        fromIndex = 0;
      }
    }

    for (; fromIndex < length; fromIndex++) {
      if (this[fromIndex] === searchElement) {
        return fromIndex;
      }
    }

    return -1;
  };
}

if (!Function.prototype.bind) {
  Function.prototype.bind = function (oThis) {
    if (typeof this !== 'function') {
      // closest thing possible to the ECMAScript 5 internal IsCallable function
      throw new TypeError('Function.prototype.bind - what is trying to be bound is not callable');
    }

    var aArgs = Array.prototype.slice.call(arguments, 1),
        fToBind = this,
        fNOP = function fNOP() {},
        fBound = function fBound() {
      return fToBind.apply(this instanceof fNOP && oThis ? this : oThis, aArgs.concat(Array.prototype.slice.call(arguments)));
    };

    fNOP.prototype = this.prototype;
    fBound.prototype = new fNOP();
    return fBound;
  };
}
/*
 * @author Joshua Hurst
 * @date 6/19/2015
 *
 * @description A Vanilla JS Wrapper for Local Storage
 */


(function (LIVEREZ, window, $, undefined) {
  'use strict';

  var LocalStorage = {}; // test if local storage is supported

  LocalStorage.localStoreSupport = function () {
    try {
      localStorage.setItem('localstoragesupporttest', 1);
      localStorage.removeItem('localstoragesupporttest');
      return 'localStorage' in window && window['localStorage'];
    } catch (e) {
      return false;
    }
  }; // save the data


  LocalStorage.set = function (key, value, days) {
    value = JSON.stringify(value);

    if (days) {
      var date = new Date();
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
      var expires = '; expires=' + date.toGMTString();
    } else {
      var expires = '';
    }

    if (this.localStoreSupport()) {
      localStorage.setItem(key, value);
    } else {
      document.cookie = key + '=' + value + expires + '; path=/';
    }
  }; // retrieve the stored data by key if it exists


  LocalStorage.get = function (key) {
    if (this.localStoreSupport()) {
      if (this.exists(key)) {
        return JSON.parse(localStorage.getItem(key));
      }
    } else {
      var nameEQ = key + '=';
      var ca = document.cookie.split(';');

      for (var i = 0; i < ca.length; i++) {
        var c = ca[i];

        while (c.charAt(0) == ' ') {
          c = c.substring(1, c.length);
        }

        if (c.indexOf(nameEQ) == 0) return JSON.parse(c.substring(nameEQ.length, c.length));
      }

      return null;
    }
  }; // remove stored data by key if it exists


  LocalStorage.remove = function (key) {
    if (this.localStoreSupport()) {
      if (this.exists(key)) {
        localStorage.removeItem(key);
      }
    } else {
      this.set(key, '', -1);
    }
  };

  LocalStorage.exists = function (key) {
    return localStorage.getItem(key) !== null;
  };

  LIVEREZ.Storage = LocalStorage;
})(window.LIVEREZ = window.LIVEREZ || {}, window, lrjQ);
/*
 * @author Joshua Hurst & Frank Linehan
 * @date 6/16/2015
 *
 * @description A Pub/Sub Pattern Module for Handling JS Events
 */


(function (LIVEREZ, window, $, undefined) {
  var pubsub = {};
  var topics = {}; // An topic identifier

  var subUid = -1;

  pubsub.publish = function (topic, args) {
    if (!topics[topic]) {
      return false;
    }

    var subscribers = topics[topic],
        len = subscribers ? subscribers.length : 0;

    while (len--) {
      subscribers[len].func(topic, args);
    }

    return this;
  };

  pubsub.subscribe = function (topic, func) {
    if (!topics[topic]) {
      topics[topic] = [];
    }

    var token = (++subUid).toString();
    topics[topic].push({
      token: token,
      func: func
    });
    return token;
  };

  pubsub.unsubscribe = function (token) {
    for (var m in topics) {
      if (topics[m]) {
        for (var i = 0, j = topics[m].length; i < j; i++) {
          if (topics[m][i].token === token) {
            topics[m].splice(i, 1);
            return token;
          }
        }
      }
    }

    return this;
  };

  LIVEREZ.Publisher = pubsub;
})(window.LIVEREZ = window.LIVEREZ || {}, window, lrjQ);
/* =========================================================
 * lr.typeahead.js
 * Repo: http://stash.liverez.com:7990/scm/bow/lr.typeahead.git
 * =========================================================
 * Authored by LIVEREZ.COM
 * Contributors: Joshua Hurst, Frank Linehan, Jeremy Lotz
 * ========================================================= */


(function ($, undefined) {
  'use strict';

  var _timeout;

  var DEFAULTS = {
    timeoutLength: 500,
    dataSource: function dataSource(callback, query) {
      var _sendData = {
        q: encodeURIComponent(query)
      };
      $.ajax({
        type: 'GET',
        url: 'data/properties.json',
        data: _sendData,
        dataType: 'json',
        success: function success(data) {
          callback(null, data);
        },
        error: function error(data, status, _error2) {
          callback({
            data: data,
            status: status,
            error: _error2
          });
        }
      });
    },
    onNewData: function onNewData(data) {
      console.log(data);
    }
  }; // the actual plugin

  var Plugin = function Plugin(option) {
    return this.each(function () {
      var $this = $(this);
      var options = _typeof(option) == 'object' && option;
      options = $.extend(DEFAULTS, options);
      new Typeahead($this, options);
    });
  }; // the constructor


  var Typeahead = function Typeahead(element, options) {
    var _this = this;

    this.element = element;
    this.options = options;
    this.init(this.element, this.options);
  };

  Typeahead.prototype.init = function (element, options) {
    var _this = this;

    var wrapperDiv = $('<div/>').css({
      position: 'relative',
      width: this.element.width() + 2
    }).addClass('lr-typeahead-wrapper');
    var typeaheadDiv = $('<div/>');
    typeaheadDiv.css({
      position: 'absolute',
      top: this.element.outerHeight(),
      left: '0',
      width: '100%',
      display: 'none',
      border: '1px solid #ccc',
      'border-top': 'none',
      background: 'white',
      color: '#444',
      'max-height': '50vh',
      'overflow-y': 'scroll'
    }).html('Loading Data...').addClass('lr-typeahead');
    this.element.wrap(wrapperDiv);
    this.element.after(typeaheadDiv);
    element.on('keydown', function () {
      _this.open('keydown', typeaheadDiv, _this.element);
    });
    element.on('keyup', function () {
      if (_this.element.val()) {
        if (_timeout) {
          clearTimeout(_timeout);
        }

        _timeout = setTimeout(function () {
          _this.getData('keyup', function (error, data) {
            if (error) {
              typeaheadDiv.html('No Results');
            } else {
              _this.insertTypeAheadData(typeaheadDiv, data);
            }
          });
        }, _this.options.timeoutLength);
      } else {
        _this.close('keyup', typeaheadDiv);
      }
    });
  };

  Typeahead.prototype.open = function (type, typeaheadelem, element) {
    var domTypeAheadElem = typeaheadelem.get(0);
    typeaheadelem.html('Loading Data...');

    if (domTypeAheadElem.style.display === 'none') {
      $('body').on('click', function (e) {
        if (!typeaheadelem.is(e.target) && typeaheadelem.has(e.target).length === 0) {
          domTypeAheadElem.style.display = 'none';
          element.val('');
        }

        $('body').off('click');
      });
      domTypeAheadElem.style.display = 'block';
    }
  };

  Typeahead.prototype.close = function (type, typeaheadelem) {
    var _this = this;

    var domTypeAheadElem = typeaheadelem.get(0);

    if (domTypeAheadElem.style.display === 'block') {
      domTypeAheadElem.style.display = 'none';
    }

    return this;
  };

  Typeahead.prototype.insertTypeAheadData = function (typeaheadelem, data) {
    var _this = this;

    typeaheadelem.empty();
    typeaheadelem.append(_this.options.onNewData(data));
    return this;
  };

  Typeahead.prototype.getData = function (type, callback) {
    var _this = this;

    _this.options.dataSource(callback, _this.element.val());

    return this;
  };

  var old = $.fn.lrtypeahead;
  $.fn.lrtypeahead = Plugin;
  $.fn.lrtypeahead.Constructor = Typeahead;
})(lrjQ);

(function (LIVEREZ, window, $) {
  $(document).ready(function () {
    /****************** secondary search box desktop date pickers ******************/
    var nowTemp = new Date();
    var now = new Date(nowTemp.getFullYear(), nowTemp.getMonth(), nowTemp.getDate(), 0, 0, 0, 0);
    $('.datepicker-range').datepicker({
      beforeShowDay: function beforeShowDay(date) {
        return date.valueOf() < now.valueOf() ? 'disabled' : '';
      },
      format: dateFormat,
      autoclose: true,
      orientation: 'auto'
    }).on('changeDate', function (e) {
      if (e.target.id == 'txtStartDate') {
        var startDate = new Date(e.date);
        var newDate = new Date(e.date);
        newDate.setDate(newDate.getDate() + defaultSearchDepartureDays);
        $('#txtEndDate').datepicker('setDate', newDate);
        $('#txtEndDate').focus();
        $('#compareStartDate').val(startDate.getMonth() + 1 + '/' + startDate.getDate() + '/' + startDate.getFullYear());
      }

      if (e.target.id == 'txtEndDate') {
        var newEndDate = new Date(e.date);
        $('#compareEndDate').val(newEndDate.getMonth() + 1 + '/' + newEndDate.getDate() + '/' + newEndDate.getFullYear());
      }
    }).data('datepicker');
    /****************** end desktop date pickers ******************/

    /****************** calendar page date picker ******************/

    $('.lr-calendar-search-desktop-datepicker').datepicker({
      beforeShowDay: function beforeShowDay(date) {
        return date.valueOf() < now.valueOf() ? 'disabled' : '';
      },
      format: dateFormat,
      autoclose: true,
      orientation: 'auto'
    });
    LIVEREZ.Publisher.subscribe('mobiscroll-loaded', function (message) {
      $('.lr-calendar-search-mobile-datepicker').mobiscroll().calendar({
        theme: 'bootstrap',
        display: 'modal',
        controls: ['calendar'],
        minDate: now,
        dateFormat: dateFormat
      });
      $('.lr-calendar-search-mobile-datepicker').change(function () {
        $('.lr-calendar-search-desktop-datepicker').val(this.value);
      });
    });
    /****************** end calendar page date picker ******************/

    LIVEREZ.Publisher.subscribe('mobiscroll-loaded', function (message) {
      /****************** secondary search box mobile date pickers ******************/
      var mobileStartInst = $('#txtStartDate-mobile').mobiscroll().calendar({
        theme: 'bootstrap',
        display: 'modal',
        controls: ['calendar'],
        minDate: now,
        closeOnSelect: true,
        dateFormat: dateFormat
      }).mobiscroll('getInst');
      var mobileEndInst = $('#txtEndDate-mobile').mobiscroll().calendar({
        theme: 'bootstrap',
        display: 'modal',
        controls: ['calendar'],
        minDate: now,
        closeOnSelect: true,
        dateFormat: dateFormat
      }).mobiscroll('getInst');
      $('#txtStartDate-mobile').change(function () {
        $('#txtStartDate').val(this.value);
        $('#compareStartDate').val(this.value);
        var startDateStr = LIVEREZ.DynsiteFunctions.fixEuroDatesForAPI($('#txtStartDate-mobile').val(), dateFormatType);
        var startDate = new Date(startDateStr);
        var endDateStr = LIVEREZ.DynsiteFunctions.fixEuroDatesForAPI($('#txtEndDate-mobile').val(), dateFormatType);
        var endDate = new Date(endDateStr); //console.log('startDateStr', startDateStr);
        //console.log('endDateStr', endDateStr);
        //console.log('startDate', startDate);
        //console.log('endDate', endDate);

        var diff = startDate - endDate;
        var days = diff / 1000 / 60 / 60 / 24; //console.log('diff', diff);
        //console.log('days', days);

        var newEnd = new Date(startDate);
        newEnd.setDate(startDate.getDate() + defaultSearchDepartureDays); //console.log('newEnd', newEnd);

        var newEndStr = LIVEREZ.DynsiteFunctions.fixEuroDatesForAPI(LIVEREZ.DynsiteFunctions.DateShort(newEnd), dateFormatType); //console.log('newEndStr', newEndStr);

        if (startDate > endDate) {
          mobileEndInst.setDate(newEnd);
          $('#txtEndDate-mobile').val(newEndStr);
          $('#txtEndDate').val(newEndStr);
          $('#compareEndDate').val(newEndStr);
        }
      });
      $('#txtEndDate-mobile').change(function () {
        $('#txtEndDate').val(this.value);
        $('#compareEndDate').val(this.value);
      });
    }); // lazy load echo images

    echo.init({
      throttle: 50,
      offsetVertical: 750
    });

    if ($('.index-features-carousel').length) {
      $('.index-features-carousel').on('slid.bs.carousel', function (e) {
        echo.init();
      });
    }

    if ($('.alternative-properties-carousel').length) {
      $('.alternative-properties-carousel').on('slid.bs.carousel', function (e) {
        echo.init();
      });
    } // only load mobiscroll if mobile to because its not neccesary on desktop


    if (LIVEREZ.DynsiteFunctions.isMobileDevice()) {
      $.getScript(CDN_BASE_URL + '/3/common/js/mobiscroll.custom-2.10.1.min.js', function () {
        $('head').append($('<link rel="stylesheet" type="text/css" />').attr('href', CDN_BASE_URL + '/3/common/css/mobiscroll.custom-2.10.1.min.css'));
        LIVEREZ.Publisher.publish('mobiscroll-loaded', 'ready');
      });
    }

    $('.popover-icon').popover({
      placement: 'top',
      trigger: 'hover'
    });

    if ($('.lr-typeahead').length) {
      var myOptions = {
        timeoutLength: 600,
        dataSource: function dataSource(callback, query) {
          $.ajax({
            type: 'GET',
            url: '/inc/api/webservices.aspx?method=propertysearch',
            data: {
              AdminCustDataID: ADMIN_CUST_DATA_ID,
              DynSiteID: DYN_SITE_ID,
              q: encodeURIComponent(query)
            },
            dataType: 'json',
            success: function success(data) {
              callback(null, data);
            },
            error: function error(data, status, _error3) {
              console({
                data: data,
                status: status,
                error: _error3
              });
              callback(null, []);
            }
          });
        },
        onNewData: function onNewData(data, element) {
          if (data.length) {
            var retHtml = '';

            for (var i = 0; i < data.length; i++) {
              retHtml += '<div><a href="javascript: LIVEREZ.DynsiteFunctions.goPropertyByID(' + data[i].pageDataID + ')">' + data[i].pid + '</a></div>';
            }

            return retHtml;
          } else {
            return '<div><a href="vacation-rentals-homes.asp">No Results</a></div>';
          }
        }
      };
      $('.lr-typeahead').lrtypeahead(myOptions);
    }
  });
})(window.LIVEREZ = window.LIVEREZ || {}, window, lrjQ);

(function (LIVEREZ, window, $, undefined) {
  'use strict';

  var DAYS_OF_WEEK = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  var PropertyDateRanges = {};

  PropertyDateRanges.getByPageDataID = function (pageDataId, callback) {
    LIVEREZ.Publisher.publish(LIVEREZ.events.propertyDateRangeStart);
    $.ajax({
      type: 'get',
      url: LIVEREZ.DynsiteFunctions.getServerName() + '/inc/api/webservices.aspx?method=dateranges',
      data: {
        AdminCustDataID: ADMIN_CUST_DATA_ID,
        DynSiteID: DYN_SITE_ID,
        PageDataID: pageDataId
      },
      dataType: 'json',
      success: function success(data) {
        // publish the data to all subscribers
        LIVEREZ.Publisher.publish(LIVEREZ.events.propertyDateRangeComplete);
        LIVEREZ.Publisher.publish(LIVEREZ.events.propertyDateRangeSuccess, data);
        callback(null, data);
      },
      error: function error(data, status, _error4) {
        LIVEREZ.Publisher.publish(LIVEREZ.events.propertyDateRangeComplete);

        if (_error4 !== 'abort') {
          LIVEREZ.Publisher.publish(LIVEREZ.events.propertyDateRangeError, _error4);
          callback(_error4);
        } else {
          var fakeData = {
            dateRanges: []
          };
          LIVEREZ.Publisher.publish(LIVEREZ.events.propertyDateRangeSuccess, fakeData);
          callback(null, fakeData);
        }
      }
    });
  };

  PropertyDateRanges.buildDateRangeTable = function (preferences, dateRanges) {
    var container = $('<div />').addClass('table-responsive');
    var table = $(' <table />').addClass('table table-striped responsive-rate-table');
    var tableBody = $('	<tbody />');
    table.append(tableBody);
    tableBody.append(_buildHeaderRow(preferences));
    dateRanges.forEach(function (dateRange, i) {
      table.append(_buildDateRangeRow(preferences, dateRange, i));
    });
    container.append(table);
    return container;
  };

  var _buildHeaderRow = function _buildHeaderRow(preferences) {
    var row = $('<tr />');
    row.append($('<td />').addClass('property-rate-titles').html('<strong>Season</strong>'));
    row.append($('<td />').addClass('property-rate-titles').html('<strong>Start</strong>'));
    row.append($('<td />').addClass('property-rate-titles').html('<strong>End</strong>'));

    if (preferences.nightlyPricing) {
      if (preferences.pricingPerDayOfWeek) {
        row.append($('<td />').addClass('property-rate-titles').html('<strong>Sun</strong>'));
        row.append($('<td />').addClass('property-rate-titles').html('<strong>Mon</strong>'));
        row.append($('<td />').addClass('property-rate-titles').html('<strong>Tue</strong>'));
        row.append($('<td />').addClass('property-rate-titles').html('<strong>Wed</strong>'));
        row.append($('<td />').addClass('property-rate-titles').html('<strong>Thu</strong>'));
        row.append($('<td />').addClass('property-rate-titles').html('<strong>Fri</strong>'));
        row.append($('<td />').addClass('property-rate-titles').html('<strong>Sat</strong>'));
      } else {
        row.append($('<td />').addClass('property-rate-titles property-rate-title-night').html('<strong>Nightly</strong>'));
      }
    }

    if (preferences.weeklyPricing) {
      row.append($('<td />').addClass('property-rate-titles property-rate-title-week').html('<strong>Weekly</strong>'));
    }

    if (preferences.monthlyPricing) {
      row.append($('<td />').addClass('property-rate-titles property-rate-title-month').html('<strong>Monthly</strong>'));
    }

    if (preferences.showMinStayOnProperty) {
      row.append($('<td />').addClass('property-rate-titles property-rate-title-min-night').html('<strong>Min Nights</strong>'));
    }

    return row;
  };

  var _buildDateRangeRow = function _buildDateRangeRow(preferences, dateRange, index) {
    var row = $('<tr />').addClass('property-date-range-row');

    var isRowEven = _isEven(index);

    row.addClass(isRowEven ? 'property-rate-tr-wt' : 'property-rate-tr-gr');
    row.addClass(dateRange.available ? 'available' : 'unavailable');
    row.append($('<td />').html(dateRange.rangeTitle.trim()));
    row.append($('<td />').html(dateRange.strDateRangeL));
    row.append($('<td />').html(dateRange.strDateRangeU));
    var pricingColumns = 0;

    if (preferences.nightlyPricing) {
      if (preferences.pricingPerDayOfWeek) {
        if (dateRange.hasOwnProperty('available') && !dateRange.available) {
          pricingColumns += 7;
        } else if (dateRange.dateRangeType === 2) {
          // week to week
          row.append($('<td />').attr('colspan', 7).addClass('text-center').html(_formatPricing(preferences, dateRange.weekly) + ' Per Week'));
        } else {
          row.append($('<td />').html(_formatPricing(preferences, dateRange.sunPrice)));
          row.append($('<td />').html(_formatPricing(preferences, dateRange.monPrice)));
          row.append($('<td />').html(_formatPricing(preferences, dateRange.tuePrice)));
          row.append($('<td />').html(_formatPricing(preferences, dateRange.wedPrice)));
          row.append($('<td />').html(_formatPricing(preferences, dateRange.thuPrice)));
          row.append($('<td />').html(_formatPricing(preferences, dateRange.friPrice)));
          row.append($('<td />').html(_formatPricing(preferences, dateRange.satPrice)));
        }
      } else {
        if (dateRange.hasOwnProperty('available') && !dateRange.available) {
          pricingColumns += 1;
        } else if (dateRange.nightly && dateRange.dateRangeType !== 2) {
          // week to week
          var nightlyHTML = _formatPricing(preferences, dateRange.nightly);

          if (dateRange.startingAtNightly) {
            nightlyHTML = '<div><s>' + _formatPricing(preferences, dateRange.startingAtNightly) + '</s></div>' + nightlyHTML;
          }

          row.append($('<td />').addClass('property-rate-night').html(nightlyHTML));
        } else {
          row.append($('<td />').addClass('property-rate-week-none').html('-'));
        }
      }
    }

    if (preferences.weeklyPricing) {
      if (dateRange.hasOwnProperty('available') && !dateRange.available) {
        pricingColumns += 1;
      } else if (dateRange.weekly) {
        var weeklyHTML = _formatPricing(preferences, dateRange.weekly);

        if (dateRange.startingAtWeekly) {
          weeklyHTML = '<div><s>' + _formatPricing(preferences, dateRange.startingAtWeekly) + '</s></div>' + weeklyHTML;
        }

        row.append($('<td />').addClass('property-rate-week').html(weeklyHTML));
      } else {
        row.append($('<td />').addClass('property-rate-week-none').html('-'));
      }
    }

    if (preferences.monthlyPricing) {
      if (dateRange.hasOwnProperty('available') && !dateRange.available) {
        pricingColumns += 1;
      } else if (dateRange.monthly && dateRange.dateRangeType !== 2) {
        // week to week
        var monthlyHTML = _formatPricing(preferences, dateRange.monthly);

        if (dateRange.startingAtMonthly) {
          monthlyHTML = '<div><s>' + _formatPricing(preferences, dateRange.startingAtMonthly) + '</s></div>' + monthlyHTML;
        }

        row.append($('<td />').addClass('property-rate-month').html(monthlyHTML));
      } else {
        row.append($('<td />').addClass('property-rate-week-none').html('-'));
      }
    }

    if (preferences.showMinStayOnProperty) {
      if (dateRange.hasOwnProperty('available') && !dateRange.available) {
        pricingColumns += 1;
      } else {
        var cell = $('<td />').addClass('property-rate-min-night-days').html(dateRange.minimumNightsStayWebBooking || dateRange.minimumNightsStay || '-');

        if (dateRange.dateRangeType === 2) {
          // week to week
          cell.append(' (' + DAYS_OF_WEEK[dateRange.weekToWeekCheckinDay - 1] + ' - ' + DAYS_OF_WEEK[dateRange.weekToWeekCheckinDay - 1] + ')');
        }

        row.append(cell);
      }
    }

    if (pricingColumns) {
      row.append($('<td />').attr('colspan', pricingColumns).addClass('text-left').html('<span class="property-date-range-unavailable-text">Reserved</span>'));
    }

    return row;
  };

  var _formatPricing = function _formatPricing(preferences, price) {
    var newPrice = parseFloat(price).toFixed(2);
    return preferences.currencySymbol + newPrice.replace('.00', '').replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };

  var _isEven = function _isEven(n) {
    return n === 0 || !!(n && !(n % 2));
  };

  var _isOdd = function _isOdd(n) {
    return _isEven(Number(n) + 1);
  };

  LIVEREZ.PropertyDateRanges = PropertyDateRanges;
})(window.LIVEREZ = window.LIVEREZ || {}, window, lrjQ);

(function (LIVEREZ, window, $, undefined) {
  var InstantQuote = {};

  InstantQuote.getQuote = function (instantQuoteObj) {
    var _this = this;

    var _checkValidDates = LIVEREZ.DynsiteFunctions.checkValidStartEndDates(instantQuoteObj.StartDate, instantQuoteObj.EndDate);

    if (_checkValidDates) {
      _this.hideLoading();

      _this.setError(_checkValidDates);

      _this.showDates();

      _this.showButton('contact');

      return;
    } // if we already have an ajax request, abort it and use the new one


    if (_this.xhr) {
      _this.killAjax();
    }

    this.xhr = $.ajax({
      type: 'get',
      url: LIVEREZ.DynsiteFunctions.getServerName() + '/inc/api/webservices.aspx?method=instantquote',
      data: {
        AdminCustDataID: ADMIN_CUST_DATA_ID,
        DynSiteID: DYN_SITE_ID,
        PageDataID: instantQuoteObj.PageDataID,
        ad: instantQuoteObj.StartDate,
        dd: instantQuoteObj.EndDate,
        adults: instantQuoteObj.Adults,
        children: instantQuoteObj.Children,
        checkAvailable: true,
        LiveNetID: instantQuoteObj.LiveNetID || 0
      },
      dataType: 'json',
      success: function success(data) {
        // publish the data to all subscribers
        LIVEREZ.Publisher.publish(LIVEREZ.events.instantQuoteFinished, data);
      },
      error: function error(data, status, _error5) {
        if (_error5 !== 'abort') {
          _this.hideLoading();

          _this.setError('There was an issue with the quote. Please try new travel dates or try again later.');

          _this.showDates();

          _this.showButton('contact');
        }
      }
    });
  }; // subscribe to the instant quote finished event


  LIVEREZ.Publisher.subscribe(LIVEREZ.events.instantQuoteFinished, function (topic, data) {
    // reset
    $('.has-specials').addClass('hidden');
    $('.quote-fee-row').addClass('hidden');
    $('.discounted-rent').removeClass('on-sale'); // property is available without errors

    if (!data.error && data.isAvailable === 1) {
      $('.property-instant-quote-nightly-rate-before-specials').text(cleanPrice(data.averageDailyRateBeforeSpecials));
      $('.property-instant-quote-nightly-rate').text(cleanPrice(data.averageDailyRate));
      $('.property-instant-quote-num-nights').text(data.quantityDays);
      $('.property-instant-quote-fees').text(cleanPrice(data.fees));

      if (data.fees !== '0.00') {
        $('.quote-fee-row').removeClass('hidden');
      }

      $('.property-instant-quote-tax').text(cleanPrice(data.taxTotal));
      $('.property-instant-quote-rent').text(cleanPrice(data.rent));
      $('.property-instant-quote-rent-before-specials').text(cleanPrice(data.rentBeforeSpecials));
      $('.property-instant-quote-start-date').text(LIVEREZ.DynsiteFunctions.fixEuroDatesForAPI($('#property-instant-quote-start-date').val(), dateFormatType));
      $('.property-instant-quote-end-date').text(LIVEREZ.DynsiteFunctions.fixEuroDatesForAPI($('#property-instant-quote-end-date').val(), dateFormatType));
      $('.property-instant-quote-total-price').text(cleanPrice(data.total));
      $('.property-instant-quote-deposit-due').text(cleanPrice(data.deposit));

      if (data.hasSpecials) {
        $('.has-specials').removeClass('hidden').wrap('<s />');
        $('.discounted-rent').addClass('on-sale');
      }

      LIVEREZ.InstantQuote.showPricing();

      if (OnlineBookings) {
        LIVEREZ.InstantQuote.showButton('book');
      } else {
        LIVEREZ.InstantQuote.showButton('contact');
      }

      $('.property-instant-quote-change-dates').removeClass('hidden');
    } // error or not available
    else {
      LIVEREZ.InstantQuote.showButton('contact');
      LIVEREZ.InstantQuote.setError(data.message.split('.').join('. <br>'));
      LIVEREZ.InstantQuote.showDates();
    }

    LIVEREZ.InstantQuote.showHeading(data.isAvailable);
    LIVEREZ.InstantQuote.hideLoading();
  });

  InstantQuote.killAjax = function (strError) {
    if (this.xhr.readystate != 4) {
      this.xhr.abort();
      this.xhr = null;
    }
  };

  InstantQuote.setError = function (strError) {
    $('.property-instant-quote-message').html(strError).removeClass('hidden');
  };

  InstantQuote.removeError = function (strButtonType) {
    $('.property-instant-quote-message').empty().addClass('hidden');
  };

  InstantQuote.showButton = function (strButtonType) {
    $('.property-instant-quote-action-button').addClass('hidden');

    if (strButtonType !== '') {
      $('.property-instant-quote-button-' + strButtonType).removeClass('hidden');
    }
  };

  InstantQuote.submitForm = function (strFormType) {
    $('#property-quote-form-' + strFormType).submit();
  };

  InstantQuote.showLoading = function () {
    $('.property-instant-quote-loading').removeClass('hidden');
  };

  InstantQuote.hideLoading = function () {
    $('.property-instant-quote-loading').addClass('hidden');
  };

  InstantQuote.showDates = function () {
    $('.property-instant-quote-dates').removeClass('hidden');
  };

  InstantQuote.hideDates = function () {
    $('.property-instant-quote-dates').addClass('hidden');
  };

  InstantQuote.showPricing = function () {
    $('.property-instant-quote-available').removeClass('hidden');
  };

  InstantQuote.hidePricing = function () {
    $('.property-instant-quote-available').addClass('hidden');
    $('.property-instant-quote-available').addClass('hidden');
  };

  InstantQuote.changeDates = function () {
    $('.property-instant-quote-change-dates').addClass('hidden');
    this.killAjax();
    this.showHeading(0);
    this.removeError();
    this.hideLoading();
    this.hidePricing();
    this.showDates();
    this.showButton('');
  };

  InstantQuote.showHeading = function (isAvailable) {
    $('.property-instant-quote-heading').addClass('hidden');

    if (isAvailable === 1) {
      $('.property-instant-quote-heading-available').removeClass('hidden');
    } else {
      $('.property-instant-quote-heading-default').removeClass('hidden');
    }
  };

  LIVEREZ.InstantQuote = InstantQuote;

  function cleanPrice(decimalPrice) {
    var parts = decimalPrice.split('.');

    if (parts[1] === '00') {
      decimalPrice = parts[0];
    }

    return decimalPrice;
  }
})(window.LIVEREZ = window.LIVEREZ || {}, window, lrjQ);

(function (LIVEREZ, window, $, undefined) {
  var SearchResults = {}; // set up empty object to be used for caching data, settings while on the page

  SearchResults.cacheObj = {};
  SearchResults.cacheObj.mapData = {
    markerObjs: [],
    markersArr: [],
    isOpen: false,
    instance: null
  };

  SearchResults.setSearch = function (SearchObj) {
    this.cacheObj.SearchObj = SearchObj;
    return this;
  };

  SearchResults.searchOrder = function (OrderBy) {
    this.cacheObj.SearchObj.Sort = OrderBy;
    this.cacheObj.SearchObj.currentPage = 1;
    return this;
  };

  SearchResults.searchRun = function () {
    LIVEREZ.Publisher.publish(LIVEREZ.events.searchStart);

    var _this = this; // if we already have an ajax request, abort it and use the new one


    if (_this.xhr) {
      _this.xhr.abort();

      _this.xhr = null;
    }

    var _sendData = {
      Beds: _this.cacheObj.SearchObj.Bedrooms,
      sleeps: _this.cacheObj.SearchObj.Guests,
      arrivaldate: _this.cacheObj.SearchObj.ArrivalDate,
      departuredate: _this.cacheObj.SearchObj.DepartureDate,
      comparesearch: _this.cacheObj.SearchObj.CompareSearch,
      style: _this.cacheObj.SearchObj.Style,
      categoryid: Array.isArray(_this.cacheObj.SearchObj.CategoryID) ? _this.cacheObj.SearchObj.CategoryID.join(',') : _this.cacheObj.SearchObj.CategoryID,
      destinationID: Array.isArray(_this.cacheObj.SearchObj.DestinationID) ? _this.cacheObj.SearchObj.DestinationID.join(',') : _this.cacheObj.SearchObj.DestinationID,
      communityid: Array.isArray(_this.cacheObj.SearchObj.CommunityID) ? _this.cacheObj.SearchObj.CommunityID.join(',') : _this.cacheObj.SearchObj.CommunityID,
      admincustdataid: _this.cacheObj.SearchObj.AdminCustDataID,
      dynsiteid: _this.cacheObj.SearchObj.DynsiteID,
      searchtype: _this.cacheObj.SearchObj.SearchType,
      searchOrigin: _this.cacheObj.SearchObj.SearchOrigin,
      advs: _this.cacheObj.SearchObj.AdvancedSearch,
      pagedataid: _this.cacheObj.SearchObj.pagedataid,
      pageSize: _this.cacheObj.SearchObj.pageSize,
      currentPage: _this.cacheObj.SearchObj.currentPage,
      flexibleDates: _this.cacheObj.SearchObj.flexibleDates,
      searchAmenities: _this.cacheObj.SearchObj.searchAmenities,
      showOnlySpecials: _this.cacheObj.SearchObj.showOnlySpecials,
      flexibleDatesThreshold: _this.cacheObj.SearchObj.flexibleDatesThreshold
    }; // console.log(_sendData);

    if (_this.cacheObj.SearchObj.Sort > 0) {
      _sendData.O = this.cacheObj.SearchObj.Sort;
    }

    _this.xhr = $.ajax({
      type: 'get',
      url: LIVEREZ.DynsiteFunctions.getServerName() + '/inc/api/webservices.aspx?method=searchresults',
      data: _sendData,
      dataType: 'json',
      success: function success(data, status, error) {
        data.comparesearch = _sendData.comparesearch;
        LIVEREZ.Publisher.publish(LIVEREZ.events.searchComplete, data);
      },
      error: function error(data, status, _error6) {
        if (!status === 'abort') {
          LIVEREZ.Publisher.publish(LIVEREZ.events.searchComplete, {
            error: _error6
          });
        }
      }
    });
  };

  SearchResults.getCalendarSuggestions = function (preferences) {
    var _searchProperties = [];

    if (preferences.hasOwnProperty('properties') && Array.isArray(preferences.properties) && preferences.properties.length) {
      _searchProperties = preferences.properties.map(function (property) {
        return property.pageDataID;
      });
    }

    LIVEREZ.Publisher.publish(LIVEREZ.events.searchSuggestionsStart);

    var _this = this; // if we already have an ajax request, abort it and use the new one


    if (_this.csxhr) {
      _this.csxhr.abort();

      _this.csxhr = null;
    }

    var searchedDate = new Date(_this.cacheObj.SearchObj.ArrivalDate);
    var searchEnd = new Date(_this.cacheObj.SearchObj.DepartureDate);

    var _daysSearched = (searchEnd - searchedDate) / (1000 * 60 * 60 * 24);

    _this.daysBack = 5;
    _this.daysForward = _daysSearched > 15 ? _daysSearched + 5 : 15;
    var startDate = LIVEREZ.DynsiteFunctions.dateObjectToString(new Date(new Date(searchedDate).setDate(searchedDate.getDate() - _this.daysBack)));
    var stopDate = LIVEREZ.DynsiteFunctions.dateObjectToString(new Date(new Date(searchedDate).setDate(searchedDate.getDate() + _this.daysForward)));
    var consecutiveDays = Math.floor(_daysSearched * 0.8);

    if (consecutiveDays < 2) {
      consecutiveDays = 2;
    }

    var _sendData = {
      bedrooms: _this.cacheObj.SearchObj.Bedrooms,
      guests: _this.cacheObj.SearchObj.Guests,
      startDate: startDate,
      endDate: stopDate,
      categoryid: Array.isArray(_this.cacheObj.SearchObj.CategoryID) ? _this.cacheObj.SearchObj.CategoryID.join(',') : _this.cacheObj.SearchObj.CategoryID,
      destinationID: Array.isArray(_this.cacheObj.SearchObj.DestinationID) ? _this.cacheObj.SearchObj.DestinationID.join(',') : _this.cacheObj.SearchObj.DestinationID,
      communityid: Array.isArray(_this.cacheObj.SearchObj.CommunityID) ? _this.cacheObj.SearchObj.CommunityID.join(',') : _this.cacheObj.SearchObj.CommunityID,
      admincustdataid: _this.cacheObj.SearchObj.AdminCustDataID,
      dynsiteid: _this.cacheObj.SearchObj.DynsiteID,
      consecutiveDays: consecutiveDays,
      maxProperties: 3,
      searchOrigin: _this.cacheObj.SearchObj.SearchOrigin
    };
    _this.csxhr = $.ajax({
      type: 'get',
      url: LIVEREZ.DynsiteFunctions.getServerName() + '/inc/api/webservices.aspx?method=calendarsuggestions',
      data: _sendData,
      dataType: 'json',
      success: function success(data, status, error) {
        var finalProps = [];

        if (data.length) {
          finalProps = data.filter(function (property) {
            return _searchProperties.indexOf(property.PageDataID) === -1;
          });
        }

        LIVEREZ.Publisher.publish(LIVEREZ.events.searchSuggestionsComplete, {
          preferences: preferences,
          data: finalProps
        });
      },
      error: function error(data, status, _error7) {
        if (!status === 'abort') {
          LIVEREZ.Publisher.publish(LIVEREZ.events.searchSuggestionsComplete, {
            error: _error7
          });
        } else {
          LIVEREZ.Publisher.publish(LIVEREZ.events.searchSuggestionsComplete, {
            error: 'error fetching results'
          });
        }
      }
    });
  };

  SearchResults.CompareProperties = function () {
    var _this = this;

    if (_this.CheckCompare()) {
      document.Search_xml.PageDataID.value = _this.CompareCheckboxArr.join(',');
      document.Search_xml.submit();
    }
  };

  SearchResults.CheckCompare = function () {
    var _this = this;

    _this.CompareCheckboxArr = [];
    $('input:checkbox[name=PageDataID]:checked').each(function () {
      _this.CompareCheckboxArr.push($(this).val());
    });

    if (this.CompareCheckboxArr.length == 0) {
      alert('No Properties were selected. Please select up to 3 properties to compare.');
      return false;
    } else if (this.CompareCheckboxArr.length == 1) {
      alert('You must select more than 1 property to compare homes.');
      return false;
    } else if (this.CompareCheckboxArr.length > 3) {
      alert('To compare homes, You can only select 3 homes at a time.');
      return false;
    }

    return true;
  };

  SearchResults.setView = function (strView) {
    LIVEREZ.Storage.set('SearchResultsView', strView);
    return this;
  };

  SearchResults.getView = function (strView) {
    return LIVEREZ.Storage.get('SearchResultsView');
  };

  SearchResults.saveResultsData = function (resultsData) {
    this.cacheObj.results = resultsData;
    return this;
  };

  SearchResults.renderResultsbyView = function (callback, addtPropAvailMsgLocation) {
    if (this.cacheObj.results) {
      var data = this.cacheObj.results;

      var _this = this;

      var errorMsg = '';
      var propertiesDiv = $('<div class="search-results-properties-container" />');
      _this.cacheObj.mapData.markerObjs.length = 0;

      if (data.properties && data.properties.length) {
        var propertiesStr = '';

        if (_this.getView() === 'grid') {
          propertiesStr += '<div class="row property-list-wrapper grid-view"><ul class="row-same-height row-full-height list-unstyled">';
        }

        data.properties.forEach(function (propData, index) {
          if (_this.getView() === 'grid') {
            if (index > 0 && index % 3 === 0) {
              propertiesStr += '</ul></div><div class="row property-list-wrapper grid-view"><ul class="row-same-height row-full-height list-unstyled">';
            }

            propertiesStr += _this.SearchResultsTemplateGrid(data, propData);
          } else {
            propertiesStr += _this.SearchResultsTemplateList(data, propData);
          } // add the property data to the markers object array for use later signifying this property has a lat and long


          if (propData.latitude && propData.longitude) {
            propData.currencySymbol = propData.currencySymbol || data.currencySymbol;
            propData.currency = propData.currency || data.currency;

            _this.cacheObj.mapData.markerObjs.push(propData);
          }
        });

        if (_this.getView() === 'grid') {
          propertiesStr += '</ul></div>';
        }

        propertiesDiv.append(propertiesStr); // create a map button for users to click

        if (_this.cacheObj.mapData.markerObjs.length > 0) {
          LIVEREZ.Publisher.publish(LIVEREZ.events.searchCreateMapImage, {
            lat: _this.cacheObj.mapData.markerObjs[0].latitude,
            lng: _this.cacheObj.mapData.markerObjs[0].longitude
          });
        }
      } else {
        if (data.returnMessageText) {
          errorMsg += data.returnMessageText;
        } else {
          errorMsg += 'We couldn’t find any results that matched your criteria.<br>Try adjusting your dates or removing some search filters to show more properties.';
        }
      } // create a week to week message if week to week are present


      if (data.weekToWeek && data.weekToWeek.hasProperties) {
        errorMsg += '<div class="week-to-week-message-container">';

        if (data.properties && data.properties.length) {
          errorMsg += '<h4>Additional Properties Available</h4>';
        }

        errorMsg += '<div>' + '</div>';
        errorMsg += '<div class="row">';

        if (data.weekToWeek.prevSuggestions.length) {
          errorMsg += '<div class="col-sm-6">';
          errorMsg += '<div class="w2w-range-heading"><strong>Try Previous Week</strong></div>';
          data.weekToWeek.prevSuggestions.forEach(function (suggestion) {
            //mutation is bad :(
            suggestion.fixedStartDate = LIVEREZ.DynsiteFunctions.fixEuroDatesForAPI(suggestion.startDate, dateFormatType);
            suggestion.fixedEndDate = LIVEREZ.DynsiteFunctions.fixEuroDatesForAPI(suggestion.endDate, dateFormatType);
            errorMsg += '<div><a href="javascript: LIVEREZ.SearchResults.changeDates(\'' + suggestion.fixedStartDate + "', '" + suggestion.fixedEndDate + '\'); _SetSearch();">' + LIVEREZ.DynsiteFunctions.truncate(suggestion.startingDay, 3) + ' ' + suggestion.fixedStartDate + ' - ' + LIVEREZ.DynsiteFunctions.truncate(suggestion.startingDay, 3) + ' ' + suggestion.fixedEndDate + '</a></div>';
          });
          errorMsg += '</div>';
        }

        if (data.weekToWeek.nextSuggestions.length) {
          errorMsg += '<div class="col-sm-6">';
          errorMsg += '<div class="w2w-range-heading"><strong>Try Next Week</strong></div>';
          data.weekToWeek.nextSuggestions.forEach(function (suggestion) {
            //mutation is bad :(
            suggestion.fixedStartDate = LIVEREZ.DynsiteFunctions.fixEuroDatesForAPI(suggestion.startDate, dateFormatType);
            suggestion.fixedEndDate = LIVEREZ.DynsiteFunctions.fixEuroDatesForAPI(suggestion.endDate, dateFormatType);
            errorMsg += '<div><a href="javascript: LIVEREZ.SearchResults.changeDates(\'' + suggestion.fixedStartDate + "', '" + suggestion.fixedEndDate + '\'); _SetSearch();">' + LIVEREZ.DynsiteFunctions.truncate(suggestion.startingDay, 3) + ' ' + suggestion.fixedStartDate + ' - ' + LIVEREZ.DynsiteFunctions.truncate(suggestion.startingDay, 3) + ' ' + suggestion.fixedEndDate + '</a></div>';
          });
          errorMsg += '</div>';
        }

        errorMsg += '</div>';
        errorMsg += '</div>';
      } else if (data.minimumNightsStay && data.minimumNightsStay.hasProperties && data.minimumNightsStay.showMinNightsStayMessage !== false) {
        if (data.properties && data.properties.length) {
          errorMsg += '<h4>Additional Properties Available</h4>';
        }

        errorMsg += '<div style="margin-top: 10px">' + '</div>';
      } // if error message exists, add it to the results


      if (errorMsg !== '') {
        if (addtPropAvailMsgLocation === 'top') {
          propertiesDiv.prepend('<div class="search-results-error-message">' + errorMsg + '</div>');
        } else {
          propertiesDiv.append('<div class="search-results-error-message">' + errorMsg + '</div>');
        }
      }

      try {
        setTimeout(function () {
          LIVEREZ.Publisher.publish(LIVEREZ.events.resultsRenderComplete);
        });
      } catch (error) {
        console.log(error);
      }

      callback(propertiesDiv);
    } // no data stored, go get some data
    else {
      SearchResults.searchRun();
    }
  };

  SearchResults.createMap = function (mapType) {
    var _this = this;

    var markers = this.cacheObj.mapData.markerObjs;
    $('.search-results-map-view-header-span').text('Viewing Map for ' + markers.length + ' Vacation Rentals');

    if (mapType === LIVEREZ.constants.MAP_TYPE.google) {
      var mapOptions = {
        zoom: 10,
        center: new google.maps.LatLng(markers[0].latitude, markers[0].longitude),
        styles: [{
          featureType: 'poi.business',
          elementType: 'labels',
          stylers: [{
            visibility: 'off'
          }]
        }]
      };
      var map = new google.maps.Map(document.getElementById('map_canvas'), mapOptions);
      _this.cacheObj.mapData.instance = map; // create a single infowindow so that only 1 can be open at a time

      this.infowindow = new google.maps.InfoWindow();
    } else if (mapType === LIVEREZ.constants.MAP_TYPE.leaflet) {
      if (_this.cacheObj.mapData.instance) {
        _this.cacheObj.mapData.instance.off();

        _this.cacheObj.mapData.instance.remove();
      }

      var mapOptions = {
        scrollWheelZoom: false
      };
      var map = L.map('map_canvas', mapOptions);
      _this.cacheObj.mapData.instance = map; //delete all the current markers from the map before we proceed to create / add new ones

      _this.deleteAllMapMarkers(mapType);

      if (Array.isArray(markers) && markers.length) {
        map.setView([markers[0].latitude, markers[0].longitude], 10);
      } else {
        map.setView([43.68131, -116.362402], 10);
      }

      L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        attribution: '&copy; <a href="https://openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      }).addTo(map);
    }

    this.createMapMarkers(map, markers, mapType);
  };

  SearchResults.deleteAllMapMarkers = function (mapType) {
    var _this = this;

    for (var i = 0; i < this.cacheObj.mapData.markersArr.length; i++) {
      if (mapType === LIVEREZ.constants.MAP_TYPE.google) {
        _this.cacheObj.mapData.markersArr[i].setMap(null);
      } else if (mapType === LIVEREZ.constants.MAP_TYPE.leaflet) {
        if (_this.cacheObj.mapData.instance) {
          _this.cacheObj.mapData.instance.removeLayer(_this.cacheObj.mapData.markersArr[i]);
        }
      }
    }

    this.cacheObj.mapData.markersArr.length = 0;
  };

  SearchResults.createMapMarkers = function (map, markers, mapType) {
    var _this = this;

    if (Array.isArray(markers) && markers.length) {
      if (mapType === LIVEREZ.constants.MAP_TYPE.google) {
        var bounds = new google.maps.LatLngBounds();
        markers.forEach(function (marker) {
          var infoWindowHTML = LIVEREZ.SearchResults.SearchResultsTemplateMapInfoWindow(marker);
          var markerLatLng = new google.maps.LatLng(marker.latitude, marker.longitude);
          var marker = new google.maps.Marker({
            position: markerLatLng,
            map: map,
            title: marker.pid
          }); // put the marker in an array so we can remove it from the map later if the results change

          _this.cacheObj.mapData.markersArr.push(marker);

          google.maps.event.addListener(marker, 'click', function () {
            _this.infowindow.setContent(infoWindowHTML);

            _this.infowindow.open(map, this);
          }); // extend the map bounds with this markers position

          bounds.extend(markerLatLng);
        }); // set the map to fit the bounds

        map.fitBounds(bounds);
      } else if (mapType === LIVEREZ.constants.MAP_TYPE.leaflet) {
        var featureGroup = L.featureGroup().addTo(map);
        markers.forEach(function (marker) {
          var infoWindowHTML = LIVEREZ.SearchResults.SearchResultsTemplateMapInfoWindow(marker);
          L.marker([marker.latitude, marker.longitude]).addTo(featureGroup).bindPopup(infoWindowHTML); // put the marker in an array so we can remove it from the map later if the results change

          _this.cacheObj.mapData.markersArr.push(marker);
        });
        featureGroup.addTo(map);
        map.fitBounds(featureGroup.getBounds());
      }
    }
  };

  SearchResults.openMap = function (mapType) {
    LIVEREZ.Publisher.publish(LIVEREZ.events.searchMapOpen);
    this.cacheObj.mapData.isOpen = true;
    this.createMap(mapType);
  };

  SearchResults.closeMap = function () {
    LIVEREZ.Publisher.publish(LIVEREZ.events.searchMapClose);
    this.cacheObj.mapData.isOpen = false;
  };

  SearchResults.changeDates = function (startD, endD) {
    $('#txtStartDate').val(startD);
    $('#txtStartDate-mobile').val(startD);
    $('#AD').val(startD);
    $('#txtEndDate').val(endD);
    $('#txtEndDate-mobile').val(endD);
    $('#DD').val(endD);
    $('#FlexibleDatesThreshold').val('');
    window.scrollTo(0, 0); //$('.start-date').val(startD);
    //$('.end-date').val(endD);

    return this;
  };

  SearchResults.renderAmenities = function (amenities, cb) {
    var str = '';

    for (var key in amenities) {
      var list = amenities[key].filter(function (item) {
        return item.hasOwnProperty('count') && item.count > 0 || item.checked;
      });

      if (Array.isArray(list) && list.length) {
        list.forEach(function (amenity, i) {
          var item = $('<div/>').addClass('search-amenities-item checkbox secondary-advanced-checkbox');
          var check = $('<input />').attr('type', 'checkbox').attr('id', 'search-amenitiy-' + amenity.amenityID).attr('name', 'searchAmenities').attr('checked', amenity.checked).val(amenity.amenityID);
          var label = $('<label />').attr('style', 'text-transform: capitalize; padding-left: 30px;').attr('for', 'search-amenitiy-' + amenity.amenityID);
          label.append(check).append(amenity.amenity);
          item.append(label);
          str += item.get(0).outerHTML;
        });
      }
    }

    cb(str);
    return this;
  };

  SearchResults.getCheckedAmenityCount = function (amenities, cb) {
    var count = 0;

    for (var key in amenities) {
      var list = amenities[key].filter(function (item) {
        return item.hasOwnProperty('checked') && !!item.checked;
      });
      count += list.length;
    }

    cb(count);
    return this;
  };

  LIVEREZ.SearchResults = SearchResults;
})(window.LIVEREZ = window.LIVEREZ || {}, window, lrjQ);

(function (LIVEREZ, window, $) {
  function _formatAvgNightlyRate(price) {
    var newPrice = parseFloat(price).toFixed(2);
    return newPrice.replace('.00', '');
  }

  LIVEREZ.SearchResults.SearchResultsTemplateList = function (preference, propData) {
    var displayPrice = _formatAvgNightlyRate(propData.averageNightlyRate);

    var preSpecialPrice = propData.hasSpecials ? _formatAvgNightlyRate(propData.averageNightlyRateBeforeSpecials) : 0;
    var displayPriceUnit = 'Per night';

    if (propData.weekToWeek && propData.weeklyPrice) {
      displayPriceUnit = 'Per week';
      displayPrice = _formatAvgNightlyRate(propData.weeklyPrice);
      preSpecialPrice = propData.hasSpecials ? _formatAvgNightlyRate(propData.weeklyPriceBeforeSpecials) : 0;
    }

    var discountPercentage = propData.hasSpecials ? Math.round((1 - displayPrice / preSpecialPrice) * 100) : 0;
    var showTagClass = propData.hasSpecials && discountPercentage > 0 ? 'property-on-sale' : '';
    var checkInDates = preference.flexibleDates ? "\n\t\t".concat(LIVEREZ.DynsiteFunctions.DateNatural(propData.startDate, false), "\n\t\t-\n\t\t").concat(LIVEREZ.DynsiteFunctions.DateNatural(propData.endDate, false), "\n\t\t") : '';
    var ret = "<div class=\"row property-list-wrapper row-view ".concat(showTagClass, " search-prop-").concat(propData.pageDataID, "\" data-percent=\"").concat(discountPercentage, "\">");
    ret += "<div class=\"row-same-height row-full-height\">";
    ret += "<div class=\"col-md-3 property-list-img-wrapper col-md-height col-lg-height col-full-height col-top\">";
    ret += "<div class=\"property-list-price-wrapper visible-sm visible-xs\">";

    if (preference.showRatesOnResults) {
      ret += "<div class=\"property-list-price-container\">";
      var currencySymbol = propData.currencySymbol || preference.currencySymbol;

      if (propData.hasSpecials && preSpecialPrice) {
        ret += "<span class=\"pre-special-price\"><s>".concat(currencySymbol + preSpecialPrice, "</s></span>");
      }

      ret += "<h2 class=\"property-list-price\"><strong>".concat(currencySymbol + displayPrice, "</strong></h2>");
      var currency = propData.currency || preference.currency;
      ret += "<div class=\"property-list-per-night\"><small>".concat(displayPriceUnit, " <strong>").concat(currency, "</strong></small></div>");
      ret += "</div>";
    }

    ret += "</div>";
    ret += "<a href=\"".concat(propData.link, "\">");
    ret += "<img class=\"img-responsive property-list-img\" src=\"".concat(CDN_BASE_URL, "/3/Images/4x3.jpg\" data-echo=\"").concat(propData.image550, "\">");
    ret += "</a>";
    ret += "<div class=\"property-list-items-wrapper visible-sm visible-xs\">";

    if (propData.headline) {
      ret += "<h4 class=\"property-list-title\"><a href=\"".concat(propData.link, "\">").concat(LIVEREZ.DynsiteFunctions.fixTextForDisplay(propData.headline), "</a></h4>");
    }

    ret += "</div>";
    ret += "</div>";
    ret += "<div class=\"col-md-7 property-list-items-wrapper col-md-height col-lg-height col-full-height col-top\">";

    if (propData.headline) {
      ret += "<h4 class=\"property-list-title hidden-sm hidden-xs\"><a href=\"".concat(propData.link, "\">").concat(LIVEREZ.DynsiteFunctions.fixTextForDisplay(propData.headline), "</a></h4>");
    }

    if (propData.location) {
      ret += "<div class=\"property-list-item property-list-item item-location\">";
      ret += "<i class=\"fa fa-map-marker\"></i> ".concat(propData.location);
      ret += "</div>";
    }

    ret += "<div class=\"row\">";
    ret += "<div class=\"col-xs-6 col-lg-4\">";
    ret += "<ul class=\"property-list-item-wrapper\">";

    if (preference.showSleepsOnSearchResults == 1) {
      ret += "<li class=\"property-list-item item-guests\"><strong>".concat(propData.maxGuests, "</strong> Guests</li>");
    }

    if (preference.showBedsOnSearchResults == 1) {
      if (propData.bedrooms === 0) {
        ret += "<li class=\"property-list-item item-bedroom\"><strong>Studio</strong> Bedrooms</li>";
      } else {
        ret += "<li class=\"property-list-item item-bedroom\"><strong>".concat(propData.bedrooms, "</strong> Bedrooms</li>");
      }
    }

    if (preference.showBathsOnSearchResults == 1) {
      ret += "<li class=\"property-list-item item-bathrooms\"><strong>".concat(propData.bathrooms, "</strong> Bathrooms</li>");
    }

    ret += "</ul>";
    ret += "</div>";
    ret += "<div class=\"col-xs-6 col-lg-8\">";
    ret += "<ul class=\"property-list-item-wrapper\">";

    if (propData.pid && preference.showPIDNumbers === 1) {
      ret += "<li class=\"property-list-item property-list-code\">";
      ret += "<span class=\"pid-text\">Code:</span> <strong>".concat(LIVEREZ.DynsiteFunctions.truncate(propData.pid, 15, true), "</strong>");
      ret += "</li>";
    }

    if (preference.showMinNightsOnSearchResults == 1) {
      ret += "<li class=\"property-list-item item-min-nights\">Minimum Stay: <strong>".concat(propData.minNightsReq, " nights</strong></li>");
    }

    if (preference.showPetsOnSearchResults == 1 && propData.allowsPets === "Yes") {
      ret += "<li class=\"property-list-item item-pets\"><i class=\"fa fa-paw\"></i> Pets Allowed</li>";
    }

    ret += "</ul>";
    ret += "</div>";
    ret += "</div>";
    checkInDates && (ret += "<div class=\"flexible-search--dates\"> <i class=\"fa fa-check\" aria-hidden=\"true\"></i> <span class=\"flex-dates-descriptor\">Available</span> ".concat(checkInDates, "</div>"));
    ret += "</div>";
    ret += "<div class=\"col-md-2 property-list-details-wrapper col-md-height col-lg-height col-full-height col-top\">";
    ret += "<div class=\"row\">";

    if (preference.showRatesOnResults) {
      ret += "<div class=\"col-sm-4 col-md-12 hidden-sm hidden-xs property-list-price-wrapper text-right\">";
      var currencySymbol = propData.currencySymbol || preference.currencySymbol;

      if (propData.hasSpecials && preSpecialPrice) {
        ret += "<span class=\"pre-special-price\"><s>".concat(currencySymbol + preSpecialPrice, "</s></span>");
      }

      ret += "<h2 class=\"property-list-price\"><strong>".concat(currencySymbol + displayPrice, "</strong></h2>");
      var currency = propData.currency || preference.currency;
      ret += "<div class=\"property-list-per-night\"><small>".concat(displayPriceUnit, " <strong>").concat(currency, "</strong></small></div>");
      ret += "</div>";
    }

    ret += "<div class=\"col-sm-4 col-md-12 property-list-btn-wrapper text-center\">";
    ret += "<a class=\"btn btn-primary btn-sm btn-block btn-view-rental\" href=\"".concat(propData.link, "\">View Rental</a>");
    ret += "</div>";

    if (preference.comparesearch === 1) {
      ret += "<div class=\"col-sm-4 col-md-12 property-list-checkbox-wrapper hidden-xs hidden-sm\">";
      ret += "<div class=\"property-list-compare text-right\" data-pagedataid=\"".concat(propData.pageDataID, "\">");
      ret += "<input class=\"checkbox-".concat(propData.pageDataID, "\" ID=\"checkbox-").concat(propData.pageDataID, "\" type=\"checkbox\" name=\"PageDataID\" value=\"").concat(propData.pageDataID, "\">");
      ret += " <label for=\"checkbox-".concat(propData.pageDataID, "\" class=\"property-list-compare-text\"><strong>Compare</strong></label>");
      ret += "</div>";
      ret += "</div>";
    }

    ret += "</div>";
    ret += "</div>";
    ret += "</div>";
    ret += "</div>";
    return ret;
  };

  LIVEREZ.SearchResults.SearchResultsTemplateGrid = function (preference, propData) {
    var displayPrice = _formatAvgNightlyRate(propData.averageNightlyRate);

    var preSpecialPrice = propData.hasSpecials ? _formatAvgNightlyRate(propData.averageNightlyRateBeforeSpecials) : 0;
    var displayPriceUnit = 'Per night';

    if (propData.weekToWeek && propData.weeklyPrice) {
      displayPriceUnit = 'Per week';
      displayPrice = _formatAvgNightlyRate(propData.weeklyPrice);
      preSpecialPrice = propData.hasSpecials ? _formatAvgNightlyRate(propData.weeklyPriceBeforeSpecials) : 0;
    }

    var discountPercentage = propData.hasSpecials ? Math.round((1 - displayPrice / preSpecialPrice) * 100) : 0;
    var showTagClass = propData.hasSpecials && discountPercentage > 0 ? 'property-on-sale' : '';
    var checkInDates = preference.flexibleDates ? "\n\t\t".concat(LIVEREZ.DynsiteFunctions.DateNatural(propData.startDate, false), "\n\t\t-\n\t\t").concat(LIVEREZ.DynsiteFunctions.DateNatural(propData.endDate, false), "\n\t\t") : '';
    var ret = "\n\t\t<li class=\"col-md-4 col-top\">\n\t\t<div class=\"property-list-content-wrapper ".concat(showTagClass, " search-prop-").concat(propData.pageDataID, "\" data-percent=\"").concat(discountPercentage, "\">\n\t\t<div class=\"property-list-img-wrapper\">\n\t\t<div class=\"property-list-price-wrapper visible-sm visible-xs\">");

    if (preference.showRatesOnResults) {
      ret += "<div class=\"property-list-price-container\">";
      var currencySymbol = propData.currencySymbol || preference.currencySymbol;

      if (propData.hasSpecials && preSpecialPrice) {
        ret += "<span class=\"pre-special-price\"><s>".concat(currencySymbol + preSpecialPrice, "</s></span>");
      }

      ret += "<h2 class=\"property-list-price\"><strong>".concat(currencySymbol + displayPrice, "</strong></h2>");
      var currency = propData.currency || preference.currency;
      ret += "<div class=\"property-list-per-night\"><small>".concat(displayPriceUnit, " <strong>").concat(currency, "</strong></small></div>");
      ret += "</div>";
    }

    ret += "\n\t\t</div>\n\t\t<a href=\"".concat(propData.link, "\"><img class=\"img-responsive property-list-img\" src=\"").concat(CDN_BASE_URL, "/3/Images/4x3.jpg\" data-echo=\"").concat(propData.image550, "\"></a>\n\t\t<div class=\"property-list-items-wrapper visible-sm visible-xs\">");

    if (propData.headline) {
      ret += "<h4 class=\"property-list-title\"><a href=\"".concat(propData.link, "\">").concat(LIVEREZ.DynsiteFunctions.fixTextForDisplay(propData.headline), "</a></h4>");
    }

    ret += "\n\t\t</div>\n\t\t</div>\n\t\t\n\t\t<div class=\"property-list-text-wrapper\">";

    if (propData.headline) {
      ret += "<h4 class=\"property-list-title hidden-sm hidden-xs\"><a href=\"".concat(propData.link, "\">").concat(LIVEREZ.DynsiteFunctions.fixTextForDisplay(propData.headline), "</a></h4>");
    }

    if (propData.location) {
      ret += "<div class=\"property-list-item item-location\"><i class=\"fa fa-map-marker\"></i> ".concat(propData.location, "</div>");
    }

    ret += "\n\t\t<div class=\"row property-list-details-wrapper\">\n\t\t<div class=\"col-xs-6 col-md-5 \">\n\t\t<ul class=\"property-list-item-wrapper\">\n\t\t";

    if (preference.showSleepsOnSearchResults == 1) {
      ret += "<li class=\"property-list-item item-guests\"><strong>".concat(propData.maxGuests, "</strong> Guests</li>");
    }

    if (preference.showBedsOnSearchResults == 1) {
      if (propData.bedrooms === 0) {
        ret += "<li class=\"property-list-item item-bedroom\"><strong>Studio</strong> Bedrooms</li>";
      } else {
        ret += "<li class=\"property-list-item item-bedroom\"><strong>".concat(propData.bedrooms, "</strong> Bedrooms</li>");
      }
    }

    if (preference.showBathsOnSearchResults == 1) {
      ret += "<li class=\"property-list-item item-bathrooms\"><strong>".concat(propData.bathrooms, "</strong> Bathrooms</li>");
    }

    ret += "</ul>\n\t\t</div>\n\t\t<div class=\"col-xs-6 col-md-7\">\n\t\t";

    if (preference.showRatesOnResults) {
      ret += "<div class=\"col-sm-4 col-md-12 property-list-price-wrapper text-right\">";
      var currencySymbol = propData.currencySymbol || preference.currencySymbol;

      if (propData.hasSpecials && preSpecialPrice) {
        ret += "<span class=\"pre-special-price\"><s>".concat(currencySymbol + preSpecialPrice, "</s></span>");
      }

      ret += "<h2 class=\"property-list-price\"><strong>".concat(currencySymbol + displayPrice, "</strong></h2>");
      var currency = propData.currency || preference.currency;
      ret += "<div class=\"property-list-per-night\"><small>".concat(displayPriceUnit, " <strong>").concat(currency, "</strong></small></div>");
      ret += "</div>";
    }

    ret += "\n\t\t</div>\n\t\t</div>\n\t\t<div class=\"row\">\n\t\t<div class=\"col-sm-12\">\n\t\t<ul class=\"property-list-item-wrapper\"\n\t\t>";

    if (propData.pid && preference.showPIDNumbers === 1) {
      ret += "<li class=\"property-list-item property-list-code\"><span class=\"pid-text\">Code:</span> <strong>".concat(LIVEREZ.DynsiteFunctions.truncate(propData.pid, 15, true), "</strong></li>");
    }

    if (preference.showMinNightsOnSearchResults == 1) {
      ret += "<li class=\"property-list-item item-min-nights\">Minimum Stay: <strong>".concat(propData.minNightsReq, " nights</strong></li>");
    }

    ret += "<li class=\"property-list-item item-pets\">";

    if (preference.showPetsOnSearchResults == 1 && propData.allowsPets === "Yes") {
      ret += "<i class=\"fa fa-paw\"></i> Pets Allowed";
    }

    ret += "</li>\n\t\t</ul>\n\t\t</div>\n\t\t</div>\n    </div>\n    ".concat(checkInDates && "<div class=\"flexible-search--dates\"><i class=\"fa fa-check\" aria-hidden=\"true\"></i> <span class=\"flex-dates-descriptor\">Available</span> ".concat(checkInDates, "</div>"), "\n\t\t<div class=\"property-list-footer\">\n\t\t<div class=\"row\" style=\"padding: 5px;\">\n\t\t");

    if (preference.comparesearch === 1) {
      ret += "\n\t\t\t<div class=\"col-sm-4 col-md-6 property-list-checkbox-wrapper\">\n\t\t\t<div class=\"property-list-compare hidden-sm hidden-xs\" data-pagedataid=\"".concat(propData.pageDataID, "\">\n\t\t\t<input class=\"checkbox-").concat(propData.pageDataID, "\" id=\"checkbox-").concat(propData.pageDataID, "\" type=\"checkbox\" name=\"PageDataID\" value=\"").concat(propData.pageDataID, "\">\n\t\t\t<label for=\"checkbox-").concat(propData.pageDataID, "\" class=\"property-list-compare-text\"><strong> Compare</strong></label>\n\t\t\t</div>\n\t\t\t</div>\n\t\t\t");
    }

    ret += "<div class=\"col-sm-4 col-md-6 property-list-btn-wrapper\">\n\t\t<a class=\"btn btn-primary btn-sm btn-block btn-view-rental\" href=\"".concat(propData.link, "\">View Rental</a>\n\t\t</div\n\t\t</div>\n\t\t</div>\n\t\t</div>\n\t\t</li>\n\t\t");
    return ret;
  };

  LIVEREZ.SearchResults.CalendarSuggestion = function (preference, propData, daysBack, daysForward) {
    var calData = propData.CalendarSuggestions.map(prepDataForStrip);
    var propertyLink = '/vacation-rental-home.asp?PageDataID=' + propData.PageDataID;

    if (propData.LiveNetID) {
      propertyLink += '&LiveNetID=' + propData.LiveNetID;
    }

    var searchedDate = new Date(preference.searchForm.startDate);
    var startDate = new Date(searchedDate).setDate(searchedDate.getDate() - daysBack);
    var stopDate = new Date(searchedDate).setDate(searchedDate.getDate() + daysForward);
    var SC = new LIVEREZ.StripCalendar(startDate, stopDate, calData);
    var htmlCal = '';
    SC.generateHtml(function (calendarHtml) {
      htmlCal = calendarHtml;
    });

    if (!preference.currency) {
      preference.currency = 'USD';
    }

    if (!preference.currencySymbol) {
      preference.currencySymbol = '$';
    }

    var currency = propData.currency || preference.currency;
    var currencySymbol = propData.currencySymbol || preference.currencySymbol;
    var ret = "<div class=\"row property-list-wrapper lr-search-calendar-suggestion row-view\">";
    ret += "<div class=\"row-same-height row-full-height\">";
    ret += "<div class=\"col-md-3 property-list-img-wrapper col-md-height col-lg-height col-full-height col-top\">";
    ret += "<div class=\"property-list-price-wrapper visible-sm visible-xs\">";

    if (propData.StartingAtPrice && preference.showRatesOnResults) {
      ret += "<div class=\"col-sm-4 col-md-12 property-list-price-wrapper text-right\">";
      ret += "<div class=\"property-list-per-night\"><small>Starting at</small></div>";
      ret += "<h2 class=\"property-list-price\"><strong>".concat(currencySymbol + _formatAvgNightlyRate(propData.StartingAtPrice), "</strong></h2>");
      ret += "<div class=\"property-list-per-night\"><small>Per night <strong>".concat(currency, "</strong></small></div>");
      ret += "</div>";
    }

    ret += "</div>";
    ret += "<a href=\"".concat(propertyLink, "\">");
    ret += "<img class=\"img-responsive property-list-img\" src=\"".concat(CDN_BASE_URL, "/3/Images/4x3.jpg\" data-echo=\"").concat(CDN_BASE_URL, "/5/").concat(propData.AdminCustDataID, "/1/").concat(propData.PageDataID, "/250/1.jpg \">");
    ret += "</a>";
    ret += "<div class=\"property-list-items-wrapper visible-sm visible-xs\">";

    if (propData.Heading) {
      ret += "<h4 class=\"property-list-title\"><a href=\"".concat(propertyLink, "\">").concat(LIVEREZ.DynsiteFunctions.fixTextForDisplay(propData.Heading), "</a></h4>");
    }

    ret += "</div>";
    ret += "</div>";
    ret += "<div class=\"col-md-7 property-list-items-wrapper col-md-height col-lg-height col-full-height col-top\">";

    if (propData.Heading) {
      ret += "<h4 class=\"property-list-title hidden-sm hidden-xs\"><a href=\"".concat(propertyLink, "\">").concat(LIVEREZ.DynsiteFunctions.fixTextForDisplay(propData.Heading), "</a></h4>");
    }

    if (propData.Location) {
      ret += "<div class=\"property-list-item property-list-item item-location\">";
      ret += "<i class=\"fa fa-map-marker\"></i> ".concat(propData.Location);
      ret += "</div>";
    }

    ret += "<div class=\"row\">";
    ret += "<div class=\"col-xs-6 col-lg-4\">";
    ret += "<ul class=\"property-list-item-wrapper\">";

    if (preference.showSleepsOnSearchResults == 1) {
      ret += "<li class=\"property-list-item item-guests\"><strong>".concat(propData.Sleeps, "</strong> Guests</li>");
    }

    if (preference.showBedsOnSearchResults == 1) {
      if (propData.Bedrooms === 0) {
        ret += "<li class=\"property-list-item item-bedroom\"><strong>Studio</strong> Bedroom</li>";
      } else {
        ret += "<li class=\"property-list-item item-bedroom\"><strong>".concat(propData.Bedrooms, "</strong> Bedrooms</li>");
      }
    }

    if (preference.showBathsOnSearchResults == 1) {
      ret += "<li class=\"property-list-item item-bathrooms\"><strong>".concat(propData.Bathrooms, "</strong> Bathrooms</li>");
    }

    ret += "</ul>";
    ret += "</div>";
    ret += "<div class=\"col-xs-6 col-lg-8\">";
    ret += "<ul class=\"property-list-item-wrapper\">";

    if (propData.PID && preference.showPIDNumbers === 1) {
      ret += "<li class=\"property-list-item property-list-code\">";
      ret += "<span class=\"pid-text\">Code:</span> <strong>".concat(LIVEREZ.DynsiteFunctions.truncate(propData.PID, 15, true), "</strong>");
      ret += "</li>";
    }

    if (preference.showMinNightsOnSearchResults == 1) {
      ret += "<li class=\"property-list-item item-min-nights\">Minimum Stay: <strong>".concat(propData.MinimumNightsStay, " nights</strong></li>");
    }

    if (preference.showPetsOnSearchResults == 1 && propData.AllowsPets === "Yes") {
      ret += "<li class=\"property-list-item item-pets\"><i class=\"fa fa-paw\"></i> Pets Allowed</li>";
    }

    ret += "</ul>";
    ret += "</div>";
    ret += "</div>";
    ret += "<div class=\"row\">";
    ret += "<div class=\"col-xs-12\">";
    ret += "<div class=\"table-responsive\">";
    ret += htmlCal;
    ret += "</div>";
    ret += "</div>";
    ret += "</div>";
    ret += "</div>";
    ret += "<div class=\"col-md-2 property-list-details-wrapper hidden-sm hidden-xs col-md-height col-lg-height col-full-height col-top\">";
    ret += "<div class=\"row\">";

    if (propData.StartingAtPrice && preference.showRatesOnResults) {
      ret += "<div class=\"col-sm-4 col-md-12 property-list-price-wrapper text-right\">";
      ret += "<div class=\"property-list-per-night\"><small>Starting at</small></div>";
      ret += "<h2 class=\"property-list-price\"><strong>".concat(currencySymbol + _formatAvgNightlyRate(propData.StartingAtPrice), "</strong></h2>");
      ret += "<div class=\"property-list-per-night\"><small>Per night <strong>".concat(currency, "</strong></small></div>");
      ret += "</div>";
    }

    ret += "<div class=\"col-sm-4 col-md-12 property-list-btn-wrapper\">";
    ret += "<a class=\"btn btn-link btn-block btn-view-rental\" href=\"".concat(propertyLink, "\">View Rental</a>");
    ret += "</div>";
    ret += "</div>";
    ret += "</div>";
    ret += "</div>";
    ret += "</div>";
    return ret;

    function prepDataForStrip(date) {
      return {
        date: normalizeDateTimeZone(new Date(date.actDate)),
        checkIn: date.CheckInCustDataID > 0,
        checkOut: date.CheckOutCustDataID > 0,
        occupied: date.CheckInCustDataID === 0 && date.CheckOutCustDataID === 0 || date.CheckInCustDataID > 0 && date.earlyCheckIn !== 0 || date.CheckOutCustDataID > 0 && date.lateCheckOut !== 0
      };
    }

    function normalizeDateTimeZone(dateOBj) {
      return new Date(dateOBj.getTime() + dateOBj.getTimezoneOffset() * 60000);
    }
  };

  LIVEREZ.SearchResults.SearchResultsTemplateMapInfoWindow = function (marker) {
    var displayPrice = _formatAvgNightlyRate(marker.averageNightlyRate);

    var preSpecialPrice = marker.hasSpecials ? _formatAvgNightlyRate(marker.averageNightlyRateBeforeSpecials) : 0;
    var displayPriceUnit = 'Per night';

    if (marker.weekToWeek && marker.weeklyPrice) {
      displayPriceUnit = 'Per week';
      displayPrice = _formatAvgNightlyRate(marker.weeklyPrice);
      preSpecialPrice = marker.hasSpecials ? _formatAvgNightlyRate(marker.weeklyPriceBeforeSpecials) : 0;
    }

    var discountPercentage = marker.hasSpecials ? Math.round((1 - displayPrice / preSpecialPrice) * 100) : 0;
    var showTagClass = marker.hasSpecials && discountPercentage > 0 ? 'property-on-sale' : '';
    var checkInDates = marker.startDate ? "\n\t\t".concat(LIVEREZ.DynsiteFunctions.DateNatural(marker.startDate, false), "\n\t\t-\n\t\t").concat(LIVEREZ.DynsiteFunctions.DateNatural(marker.endDate, false), "\n\t\t") : '';
    var ret = "\n\t\t<div class=\"search-result-map-info-window ".concat(showTagClass, "\" data-percent=\"").concat(discountPercentage, "\">\n\t\t\t<a href=\"").concat(marker.link, "\" target=\"_blank\"><img src=\"").concat(marker.image, "\" class=\"search-result-map-info-window-thumb\"></a>\n\t\t\t").concat(checkInDates && "<div class=\"flexible-search--dates\"><i class=\"fa fa-check\" aria-hidden=\"true\"></i> <span class=\"flex-dates-descriptor\">Available</span> ".concat(checkInDates, "</div>"), "\n\t\t\t<div>\n\t\t\t\t<div class=\"search-result-map-info-window-headline\">\n\t\t\t\t<a href=\"").concat(marker.link, "\" target=\"_blank\">").concat(LIVEREZ.DynsiteFunctions.fixTextForDisplay(marker.headline), "</a>\n\t\t\t</div>\n\t\t");

    if (marker.StartingAtPrice && preference.showRatesOnResults) {
      ret += "\n\t\t\t<div class=\"search-result-map-info-window-price\">";

      if (marker.hasSpecials && preSpecialPrice) {
        ret += "<span class=\"pre-special-price\"><s>".concat(currencySymbol).concat(preSpecialPrice, "</s></span>");
      }

      ret += "\n\t\t\t\t<div><strong>".concat(marker.currencySymbol).concat(displayPrice, "</strong></div>\n\t\t\t\t<div class=\"property-list-per-night\"><small>").concat(displayPriceUnit, "<strong>").concat(marker.currency, "</strong></small></div>\n\t\t\t</div>");
    }

    ret += "\n\t\t\t<div class=\"search-result-map-info-window-details\">\n\t\t\t\tBedrooms: <strong>".concat(marker.bedrooms, "</strong> Bathrooms: <strong>").concat(marker.bathrooms, "</strong> Sleeps: <strong>").concat(marker.maxGuests, "</strong>\n\t\t\t</div>");

    if (marker.location !== '') {
      ret += "\n\t\t\t<div class=\"search-result-map-info-window-location\">\n\t\t\t\tLocation: <strong>".concat(marker.location, "</strong>\n\t\t\t</div>");
    }

    ret += "\n\t\t\t<div><a href=\"".concat(marker.link, "\" class=\"btn btn-link btn-block\" target=\"_blank\"> View Rental</a></div>\n\t\t</div>\n\t\t");
    return ret;
  };

  LIVEREZ.SearchResults.FlexDatesInlinePicker = function (flexibleDatesList, currencySymbol, pricingDisplay, handleClick) {
    var sorted = flexibleDatesList.sort(function (a, b) {
      if (a.threshold < b.threshold) {
        return -1;
      }

      if (a.threshold > b.threshold) {
        return 1;
      }

      return 0;
    });
    var $container = $('<div />').addClass('flexible-dates-inline-picker');
    var daysHTML = sorted.map(function (day) {
      function formatPrice(price) {
        if (pricingDisplay === 2) {
          price = price * 7;
        } // var newPrice = (parseFloat(price) * 7).toFixed(2);


        var newPrice = parseFloat(price).toFixed(2);
        return Math.round(newPrice);
      }

      var $day = $('<div />').addClass('flexible-dates-inline-picker--day');

      if (day.active) {
        $day.addClass('active');
      }

      var $topRow = $('<div />').addClass('flexible-dates-inline-picker--day-top');
      var $bottomRow = $('<div />').addClass('flexible-dates-inline-picker--day-bottom');
      var $priceRow = $('<div />').addClass('flexible-dates-inline-picker--day-price');

      if (day.threshold > -99) {
        var formatted = LIVEREZ.DynsiteFunctions.DateNatural(day.startDate, false);
        var parts = formatted.split(' ');

        var _parts = _slicedToArray(parts, 3),
            w = _parts[0],
            m = _parts[1],
            d = _parts[2];

        $topRow.text(w);
        $bottomRow.text("".concat(m, " ").concat(d));
      } else {
        $topRow.text('ALL');
        $bottomRow.text('Dates');
      } // convert html entities to unicode


      var currencySymbolDecoded = $('<div/>').html(currencySymbol).text();
      $priceRow.text("".concat(currencySymbolDecoded).concat(formatPrice(day.startingRate)));
      $day.append($topRow);
      $day.append($bottomRow);

      if (pricingDisplay > 0) {
        $day.append($priceRow);
      }

      $day.on('click', function () {
        $('.flexible-dates-inline-picker--day.active').removeClass('active');
        $day.addClass('active');
        handleClick(day.threshold);
      });
      return $day;
    });
    $container.append(daysHTML);
    return $container;
  };
})(window.LIVEREZ = window.LIVEREZ || {}, window, lrjQ);
/*!
 * Datepicker for Bootstrap v1.7.1 (https://github.com/uxsolutions/bootstrap-datepicker)
 *
 * Licensed under the Apache License v2.0 (http://www.apache.org/licenses/LICENSE-2.0)
 */


(function (factory) {
  if (typeof define === 'function' && define.amd) {
    define(['jquery'], factory);
  } else if ((typeof exports === "undefined" ? "undefined" : _typeof(exports)) === 'object') {
    factory(require('jquery'));
  } else {
    factory(jQuery);
  }
})(function ($, undefined) {
  function UTCDate() {
    return new Date(Date.UTC.apply(Date, arguments));
  }

  function UTCToday() {
    var today = new Date();
    return UTCDate(today.getFullYear(), today.getMonth(), today.getDate());
  }

  function isUTCEquals(date1, date2) {
    return date1.getUTCFullYear() === date2.getUTCFullYear() && date1.getUTCMonth() === date2.getUTCMonth() && date1.getUTCDate() === date2.getUTCDate();
  }

  function alias(method, deprecationMsg) {
    return function () {
      if (deprecationMsg !== undefined) {
        $.fn.datepicker.deprecated(deprecationMsg);
      }

      return this[method].apply(this, arguments);
    };
  }

  function isValidDate(d) {
    return d && !isNaN(d.getTime());
  }

  var DateArray = function () {
    var extras = {
      get: function get(i) {
        return this.slice(i)[0];
      },
      contains: function contains(d) {
        // Array.indexOf is not cross-browser;
        // $.inArray doesn't work with Dates
        var val = d && d.valueOf();

        for (var i = 0, l = this.length; i < l; i++) {
          // Use date arithmetic to allow dates with different times to match
          if (0 <= this[i].valueOf() - val && this[i].valueOf() - val < 1000 * 60 * 60 * 24) return i;
        }

        return -1;
      },
      remove: function remove(i) {
        this.splice(i, 1);
      },
      replace: function replace(new_array) {
        if (!new_array) return;
        if (!$.isArray(new_array)) new_array = [new_array];
        this.clear();
        this.push.apply(this, new_array);
      },
      clear: function clear() {
        this.length = 0;
      },
      copy: function copy() {
        var a = new DateArray();
        a.replace(this);
        return a;
      }
    };
    return function () {
      var a = [];
      a.push.apply(a, arguments);
      $.extend(a, extras);
      return a;
    };
  }(); // Picker object


  var Datepicker = function Datepicker(element, options) {
    $.data(element, 'datepicker', this);

    this._process_options(options);

    this.dates = new DateArray();
    this.viewDate = this.o.defaultViewDate;
    this.focusDate = null;
    this.element = $(element);
    this.isInput = this.element.is('input');
    this.inputField = this.isInput ? this.element : this.element.find('input');
    this.component = this.element.hasClass('date') ? this.element.find('.add-on, .input-group-addon, .btn') : false;
    if (this.component && this.component.length === 0) this.component = false;
    this.isInline = !this.component && this.element.is('div');
    this.picker = $(DPGlobal.template); // Checking templates and inserting

    if (this._check_template(this.o.templates.leftArrow)) {
      this.picker.find('.prev').html(this.o.templates.leftArrow);
    }

    if (this._check_template(this.o.templates.rightArrow)) {
      this.picker.find('.next').html(this.o.templates.rightArrow);
    }

    this._buildEvents();

    this._attachEvents();

    if (this.isInline) {
      this.picker.addClass('datepicker-inline').appendTo(this.element);
    } else {
      this.picker.addClass('datepicker-dropdown dropdown-menu');
    }

    if (this.o.rtl) {
      this.picker.addClass('datepicker-rtl');
    }

    if (this.o.calendarWeeks) {
      this.picker.find('.datepicker-days .datepicker-switch, thead .datepicker-title, tfoot .today, tfoot .clear').attr('colspan', function (i, val) {
        return Number(val) + 1;
      });
    }

    this._process_options({
      startDate: this._o.startDate,
      endDate: this._o.endDate,
      daysOfWeekDisabled: this.o.daysOfWeekDisabled,
      daysOfWeekHighlighted: this.o.daysOfWeekHighlighted,
      datesDisabled: this.o.datesDisabled
    });

    this._allow_update = false;
    this.setViewMode(this.o.startView);
    this._allow_update = true;
    this.fillDow();
    this.fillMonths();
    this.update();

    if (this.isInline) {
      this.show();
    }
  };

  Datepicker.prototype = {
    constructor: Datepicker,
    _resolveViewName: function _resolveViewName(view) {
      $.each(DPGlobal.viewModes, function (i, viewMode) {
        if (view === i || $.inArray(view, viewMode.names) !== -1) {
          view = i;
          return false;
        }
      });
      return view;
    },
    _resolveDaysOfWeek: function _resolveDaysOfWeek(daysOfWeek) {
      if (!$.isArray(daysOfWeek)) daysOfWeek = daysOfWeek.split(/[,\s]*/);
      return $.map(daysOfWeek, Number);
    },
    _check_template: function _check_template(tmp) {
      try {
        // If empty
        if (tmp === undefined || tmp === '') {
          return false;
        } // If no html, everything ok


        if ((tmp.match(/[<>]/g) || []).length <= 0) {
          return true;
        } // Checking if html is fine


        var jDom = $(tmp);
        return jDom.length > 0;
      } catch (ex) {
        return false;
      }
    },
    _process_options: function _process_options(opts) {
      // Store raw options for reference
      this._o = $.extend({}, this._o, opts); // Processed options

      var o = this.o = $.extend({}, this._o); // Check if "de-DE" style date is available, if not language should
      // fallback to 2 letter code eg "de"

      var lang = o.language;

      if (!dates[lang]) {
        lang = lang.split('-')[0];
        if (!dates[lang]) lang = defaults.language;
      }

      o.language = lang; // Retrieve view index from any aliases

      o.startView = this._resolveViewName(o.startView);
      o.minViewMode = this._resolveViewName(o.minViewMode);
      o.maxViewMode = this._resolveViewName(o.maxViewMode); // Check view is between min and max

      o.startView = Math.max(this.o.minViewMode, Math.min(this.o.maxViewMode, o.startView)); // true, false, or Number > 0

      if (o.multidate !== true) {
        o.multidate = Number(o.multidate) || false;
        if (o.multidate !== false) o.multidate = Math.max(0, o.multidate);
      }

      o.multidateSeparator = String(o.multidateSeparator);
      o.weekStart %= 7;
      o.weekEnd = (o.weekStart + 6) % 7;
      var format = DPGlobal.parseFormat(o.format);

      if (o.startDate !== -Infinity) {
        if (!!o.startDate) {
          if (o.startDate instanceof Date) o.startDate = this._local_to_utc(this._zero_time(o.startDate));else o.startDate = DPGlobal.parseDate(o.startDate, format, o.language, o.assumeNearbyYear);
        } else {
          o.startDate = -Infinity;
        }
      }

      if (o.endDate !== Infinity) {
        if (!!o.endDate) {
          if (o.endDate instanceof Date) o.endDate = this._local_to_utc(this._zero_time(o.endDate));else o.endDate = DPGlobal.parseDate(o.endDate, format, o.language, o.assumeNearbyYear);
        } else {
          o.endDate = Infinity;
        }
      }

      o.daysOfWeekDisabled = this._resolveDaysOfWeek(o.daysOfWeekDisabled || []);
      o.daysOfWeekHighlighted = this._resolveDaysOfWeek(o.daysOfWeekHighlighted || []);
      o.datesDisabled = o.datesDisabled || [];

      if (!$.isArray(o.datesDisabled)) {
        o.datesDisabled = o.datesDisabled.split(',');
      }

      o.datesDisabled = $.map(o.datesDisabled, function (d) {
        return DPGlobal.parseDate(d, format, o.language, o.assumeNearbyYear);
      }); // Custom boilerplate for RES-5775

      o.datesArrival = o.datesArrival || [];

      if (!$.isArray(o.datesArrival)) {
        o.datesArrival = o.datesArrival.split(',');
      }

      o.datesArrival = $.map(o.datesArrival, function (d) {
        return DPGlobal.parseDate(d, format, o.language, o.assumeNearbyYear);
      });
      o.datesDeparture = o.datesDeparture || [];

      if (!$.isArray(o.datesDeparture)) {
        o.datesDeparture = o.datesDeparture.split(',');
      }

      o.datesDeparture = $.map(o.datesDeparture, function (d) {
        return DPGlobal.parseDate(d, format, o.language, o.assumeNearbyYear);
      }); // End custom boilerplate

      var plc = String(o.orientation).toLowerCase().split(/\s+/g),
          _plc = o.orientation.toLowerCase();

      plc = $.grep(plc, function (word) {
        return /^auto|left|right|top|bottom$/.test(word);
      });
      o.orientation = {
        x: 'auto',
        y: 'auto'
      };
      if (!_plc || _plc === 'auto') ;else if (plc.length === 1) {
        // no action
        switch (plc[0]) {
          case 'top':
          case 'bottom':
            o.orientation.y = plc[0];
            break;

          case 'left':
          case 'right':
            o.orientation.x = plc[0];
            break;
        }
      } else {
        _plc = $.grep(plc, function (word) {
          return /^left|right$/.test(word);
        });
        o.orientation.x = _plc[0] || 'auto';
        _plc = $.grep(plc, function (word) {
          return /^top|bottom$/.test(word);
        });
        o.orientation.y = _plc[0] || 'auto';
      }

      if (o.defaultViewDate instanceof Date || typeof o.defaultViewDate === 'string') {
        o.defaultViewDate = DPGlobal.parseDate(o.defaultViewDate, format, o.language, o.assumeNearbyYear);
      } else if (o.defaultViewDate) {
        var year = o.defaultViewDate.year || new Date().getFullYear();
        var month = o.defaultViewDate.month || 0;
        var day = o.defaultViewDate.day || 1;
        o.defaultViewDate = UTCDate(year, month, day);
      } else {
        o.defaultViewDate = UTCToday();
      }
    },
    _events: [],
    _secondaryEvents: [],
    _applyEvents: function _applyEvents(evs) {
      for (var i = 0, el, ch, ev; i < evs.length; i++) {
        el = evs[i][0];

        if (evs[i].length === 2) {
          ch = undefined;
          ev = evs[i][1];
        } else if (evs[i].length === 3) {
          ch = evs[i][1];
          ev = evs[i][2];
        }

        el.on(ev, ch);
      }
    },
    _unapplyEvents: function _unapplyEvents(evs) {
      for (var i = 0, el, ev, ch; i < evs.length; i++) {
        el = evs[i][0];

        if (evs[i].length === 2) {
          ch = undefined;
          ev = evs[i][1];
        } else if (evs[i].length === 3) {
          ch = evs[i][1];
          ev = evs[i][2];
        }

        el.off(ev, ch);
      }
    },
    _buildEvents: function _buildEvents() {
      var events = {
        keyup: $.proxy(function (e) {
          if ($.inArray(e.keyCode, [27, 37, 39, 38, 40, 32, 13, 9]) === -1) this.update();
        }, this),
        keydown: $.proxy(this.keydown, this),
        paste: $.proxy(this.paste, this)
      };

      if (this.o.showOnFocus === true) {
        events.focus = $.proxy(this.show, this);
      }

      if (this.isInput) {
        // single input
        this._events = [[this.element, events]];
      } // component: input + button
      else if (this.component && this.inputField.length) {
        this._events = [// For components that are not readonly, allow keyboard nav
        [this.inputField, events], [this.component, {
          click: $.proxy(this.show, this)
        }]];
      } else {
        this._events = [[this.element, {
          click: $.proxy(this.show, this),
          keydown: $.proxy(this.keydown, this)
        }]];
      }

      this._events.push( // Component: listen for blur on element descendants
      [this.element, '*', {
        blur: $.proxy(function (e) {
          this._focused_from = e.target;
        }, this)
      }], // Input: listen for blur on element
      [this.element, {
        blur: $.proxy(function (e) {
          this._focused_from = e.target;
        }, this)
      }]);

      if (this.o.immediateUpdates) {
        // Trigger input updates immediately on changed year/month
        this._events.push([this.element, {
          'changeYear changeMonth': $.proxy(function (e) {
            this.update(e.date);
          }, this)
        }]);
      }

      this._secondaryEvents = [[this.picker, {
        click: $.proxy(this.click, this)
      }], [this.picker, '.prev, .next', {
        click: $.proxy(this.navArrowsClick, this)
      }], [this.picker, '.day:not(.disabled)', {
        click: $.proxy(this.dayCellClick, this)
      }], [$(window), {
        resize: $.proxy(this.place, this)
      }], [$(document), {
        'mousedown touchstart': $.proxy(function (e) {
          // Clicked outside the datepicker, hide it
          if (!(this.element.is(e.target) || this.element.find(e.target).length || this.picker.is(e.target) || this.picker.find(e.target).length || this.isInline)) {
            this.hide();
          }
        }, this)
      }]];
    },
    _attachEvents: function _attachEvents() {
      this._detachEvents();

      this._applyEvents(this._events);
    },
    _detachEvents: function _detachEvents() {
      this._unapplyEvents(this._events);
    },
    _attachSecondaryEvents: function _attachSecondaryEvents() {
      this._detachSecondaryEvents();

      this._applyEvents(this._secondaryEvents);
    },
    _detachSecondaryEvents: function _detachSecondaryEvents() {
      this._unapplyEvents(this._secondaryEvents);
    },
    _trigger: function _trigger(event, altdate) {
      var date = altdate || this.dates.get(-1),
          local_date = this._utc_to_local(date);

      this.element.trigger({
        type: event,
        date: local_date,
        viewMode: this.viewMode,
        dates: $.map(this.dates, this._utc_to_local),
        format: $.proxy(function (ix, format) {
          if (arguments.length === 0) {
            ix = this.dates.length - 1;
            format = this.o.format;
          } else if (typeof ix === 'string') {
            format = ix;
            ix = this.dates.length - 1;
          }

          format = format || this.o.format;
          var date = this.dates.get(ix);
          return DPGlobal.formatDate(date, format, this.o.language);
        }, this)
      });
    },
    show: function show() {
      if (this.inputField.prop('disabled') || this.inputField.prop('readonly') && this.o.enableOnReadonly === false) return;
      if (!this.isInline) this.picker.appendTo(this.o.container);
      this.place();
      this.picker.show();

      this._attachSecondaryEvents();

      this._trigger('show');

      if ((window.navigator.msMaxTouchPoints || 'ontouchstart' in document) && this.o.disableTouchKeyboard) {
        $(this.element).blur();
      }

      return this;
    },
    hide: function hide() {
      if (this.isInline || !this.picker.is(':visible')) return this;
      this.focusDate = null;
      this.picker.hide().detach();

      this._detachSecondaryEvents();

      this.setViewMode(this.o.startView);
      if (this.o.forceParse && this.inputField.val()) this.setValue();

      this._trigger('hide');

      return this;
    },
    destroy: function destroy() {
      this.hide();

      this._detachEvents();

      this._detachSecondaryEvents();

      this.picker.remove();
      delete this.element.data().datepicker;

      if (!this.isInput) {
        delete this.element.data().date;
      }

      return this;
    },
    paste: function paste(e) {
      var dateString;

      if (e.originalEvent.clipboardData && e.originalEvent.clipboardData.types && $.inArray('text/plain', e.originalEvent.clipboardData.types) !== -1) {
        dateString = e.originalEvent.clipboardData.getData('text/plain');
      } else if (window.clipboardData) {
        dateString = window.clipboardData.getData('Text');
      } else {
        return;
      }

      this.setDate(dateString);
      this.update();
      e.preventDefault();
    },
    _utc_to_local: function _utc_to_local(utc) {
      if (!utc) {
        return utc;
      }

      var local = new Date(utc.getTime() + utc.getTimezoneOffset() * 60000);

      if (local.getTimezoneOffset() !== utc.getTimezoneOffset()) {
        local = new Date(utc.getTime() + local.getTimezoneOffset() * 60000);
      }

      return local;
    },
    _local_to_utc: function _local_to_utc(local) {
      return local && new Date(local.getTime() - local.getTimezoneOffset() * 60000);
    },
    _zero_time: function _zero_time(local) {
      return local && new Date(local.getFullYear(), local.getMonth(), local.getDate());
    },
    _zero_utc_time: function _zero_utc_time(utc) {
      return utc && UTCDate(utc.getUTCFullYear(), utc.getUTCMonth(), utc.getUTCDate());
    },
    getDates: function getDates() {
      return $.map(this.dates, this._utc_to_local);
    },
    getUTCDates: function getUTCDates() {
      return $.map(this.dates, function (d) {
        return new Date(d);
      });
    },
    getDate: function getDate() {
      return this._utc_to_local(this.getUTCDate());
    },
    getUTCDate: function getUTCDate() {
      var selected_date = this.dates.get(-1);

      if (selected_date !== undefined) {
        return new Date(selected_date);
      } else {
        return null;
      }
    },
    clearDates: function clearDates() {
      this.inputField.val('');
      this.update();

      this._trigger('changeDate');

      if (this.o.autoclose) {
        this.hide();
      }
    },
    setDates: function setDates() {
      var args = $.isArray(arguments[0]) ? arguments[0] : arguments;
      this.update.apply(this, args);

      this._trigger('changeDate');

      this.setValue();
      return this;
    },
    setUTCDates: function setUTCDates() {
      var args = $.isArray(arguments[0]) ? arguments[0] : arguments;
      this.setDates.apply(this, $.map(args, this._utc_to_local));
      return this;
    },
    setDate: alias('setDates'),
    setUTCDate: alias('setUTCDates'),
    remove: alias('destroy', 'Method `remove` is deprecated and will be removed in version 2.0. Use `destroy` instead'),
    setValue: function setValue() {
      var formatted = this.getFormattedDate();
      this.inputField.val(formatted);
      return this;
    },
    getFormattedDate: function getFormattedDate(format) {
      if (format === undefined) format = this.o.format;
      var lang = this.o.language;
      return $.map(this.dates, function (d) {
        return DPGlobal.formatDate(d, format, lang);
      }).join(this.o.multidateSeparator);
    },
    getStartDate: function getStartDate() {
      return this.o.startDate;
    },
    setStartDate: function setStartDate(startDate) {
      this._process_options({
        startDate: startDate
      });

      this.update();
      this.updateNavArrows();
      return this;
    },
    getEndDate: function getEndDate() {
      return this.o.endDate;
    },
    setEndDate: function setEndDate(endDate) {
      this._process_options({
        endDate: endDate
      });

      this.update();
      this.updateNavArrows();
      return this;
    },
    setDaysOfWeekDisabled: function setDaysOfWeekDisabled(daysOfWeekDisabled) {
      this._process_options({
        daysOfWeekDisabled: daysOfWeekDisabled
      });

      this.update();
      return this;
    },
    setDaysOfWeekHighlighted: function setDaysOfWeekHighlighted(daysOfWeekHighlighted) {
      this._process_options({
        daysOfWeekHighlighted: daysOfWeekHighlighted
      });

      this.update();
      return this;
    },
    setDatesDisabled: function setDatesDisabled(datesDisabled) {
      this._process_options({
        datesDisabled: datesDisabled
      });

      this.update();
      return this;
    },
    // Custom boilerplate for RES-5775
    setDatesArrival: function setDatesArrival(datesArrival) {
      this._process_options({
        datesArrival: datesArrival
      });

      this.update();
      return this;
    },
    setDatesDeparture: function setDatesDeparture(datesDeparture) {
      this._process_options({
        datesDeparture: datesDeparture
      });

      this.update();
      return this;
    },
    // End custom boilerplate
    place: function place() {
      if (this.isInline) return this;
      var calendarWidth = this.picker.outerWidth(),
          calendarHeight = this.picker.outerHeight(),
          visualPadding = 10,
          container = $(this.o.container),
          windowWidth = container.width(),
          scrollTop = this.o.container === 'body' ? $(document).scrollTop() : container.scrollTop(),
          appendOffset = container.offset();
      var parentsZindex = [0];
      this.element.parents().each(function () {
        var itemZIndex = $(this).css('z-index');
        if (itemZIndex !== 'auto' && Number(itemZIndex) !== 0) parentsZindex.push(Number(itemZIndex));
      });
      var zIndex = Math.max.apply(Math, parentsZindex) + this.o.zIndexOffset;
      var offset = this.component ? this.component.parent().offset() : this.element.offset();
      var height = this.component ? this.component.outerHeight(true) : this.element.outerHeight(false);
      var width = this.component ? this.component.outerWidth(true) : this.element.outerWidth(false);
      var left = offset.left - appendOffset.left;
      var top = offset.top - appendOffset.top;

      if (this.o.container !== 'body') {
        top += scrollTop;
      }

      this.picker.removeClass('datepicker-orient-top datepicker-orient-bottom ' + 'datepicker-orient-right datepicker-orient-left');

      if (this.o.orientation.x !== 'auto') {
        this.picker.addClass('datepicker-orient-' + this.o.orientation.x);
        if (this.o.orientation.x === 'right') left -= calendarWidth - width;
      } // auto x orientation is best-placement: if it crosses a window
      // edge, fudge it sideways
      else {
        if (offset.left < 0) {
          // component is outside the window on the left side. Move it into visible range
          this.picker.addClass('datepicker-orient-left');
          left -= offset.left - visualPadding;
        } else if (left + calendarWidth > windowWidth) {
          // the calendar passes the widow right edge. Align it to component right side
          this.picker.addClass('datepicker-orient-right');
          left += width - calendarWidth;
        } else {
          if (this.o.rtl) {
            // Default to right
            this.picker.addClass('datepicker-orient-right');
          } else {
            // Default to left
            this.picker.addClass('datepicker-orient-left');
          }
        }
      } // auto y orientation is best-situation: top or bottom, no fudging,
      // decision based on which shows more of the calendar


      var yorient = this.o.orientation.y,
          top_overflow;

      if (yorient === 'auto') {
        top_overflow = -scrollTop + top - calendarHeight;
        yorient = top_overflow < 0 ? 'bottom' : 'top';
      }

      this.picker.addClass('datepicker-orient-' + yorient);
      if (yorient === 'top') top -= calendarHeight + parseInt(this.picker.css('padding-top'));else top += height;

      if (this.o.rtl) {
        var right = windowWidth - (left + width);
        this.picker.css({
          top: top,
          right: right,
          zIndex: zIndex
        });
      } else {
        this.picker.css({
          top: top,
          left: left,
          zIndex: zIndex
        });
      }

      return this;
    },
    _allow_update: true,
    update: function update() {
      if (!this._allow_update) return this;
      var oldDates = this.dates.copy(),
          dates = [],
          fromArgs = false;

      if (arguments.length) {
        $.each(arguments, $.proxy(function (i, date) {
          if (date instanceof Date) date = this._local_to_utc(date);
          dates.push(date);
        }, this));
        fromArgs = true;
      } else {
        dates = this.isInput ? this.element.val() : this.element.data('date') || this.inputField.val();
        if (dates && this.o.multidate) dates = dates.split(this.o.multidateSeparator);else dates = [dates];
        delete this.element.data().date;
      }

      dates = $.map(dates, $.proxy(function (date) {
        return DPGlobal.parseDate(date, this.o.format, this.o.language, this.o.assumeNearbyYear);
      }, this));
      dates = $.grep(dates, $.proxy(function (date) {
        return !this.dateWithinRange(date) || !date;
      }, this), true);
      this.dates.replace(dates);

      if (this.o.updateViewDate) {
        if (this.dates.length) this.viewDate = new Date(this.dates.get(-1));else if (this.viewDate < this.o.startDate) this.viewDate = new Date(this.o.startDate);else if (this.viewDate > this.o.endDate) this.viewDate = new Date(this.o.endDate);else this.viewDate = this.o.defaultViewDate;
      }

      if (fromArgs) {
        // setting date by clicking
        this.setValue();
        this.element.change();
      } else if (this.dates.length) {
        // setting date by typing
        if (String(oldDates) !== String(this.dates) && fromArgs) {
          this._trigger('changeDate');

          this.element.change();
        }
      }

      if (!this.dates.length && oldDates.length) {
        this._trigger('clearDate');

        this.element.change();
      }

      this.fill();
      return this;
    },
    fillDow: function fillDow() {
      if (this.o.showWeekDays) {
        var dowCnt = this.o.weekStart,
            html = '<tr>';

        if (this.o.calendarWeeks) {
          html += '<th class="cw">&#160;</th>';
        }

        while (dowCnt < this.o.weekStart + 7) {
          html += '<th class="dow';
          if ($.inArray(dowCnt, this.o.daysOfWeekDisabled) !== -1) html += ' disabled';
          html += '">' + dates[this.o.language].daysMin[dowCnt++ % 7] + '</th>';
        }

        html += '</tr>';
        this.picker.find('.datepicker-days thead').append(html);
      }
    },
    fillMonths: function fillMonths() {
      var localDate = this._utc_to_local(this.viewDate);

      var html = '';
      var focused;

      for (var i = 0; i < 12; i++) {
        focused = localDate && localDate.getMonth() === i ? ' focused' : '';
        html += '<span class="month' + focused + '">' + dates[this.o.language].monthsShort[i] + '</span>';
      }

      this.picker.find('.datepicker-months td').html(html);
    },
    setRange: function setRange(range) {
      if (!range || !range.length) delete this.range;else this.range = $.map(range, function (d) {
        return d.valueOf();
      });
      this.fill();
    },
    getClassNames: function getClassNames(date) {
      var cls = [],
          year = this.viewDate.getUTCFullYear(),
          month = this.viewDate.getUTCMonth(),
          today = UTCToday();

      if (date.getUTCFullYear() < year || date.getUTCFullYear() === year && date.getUTCMonth() < month) {
        cls.push('old');
      } else if (date.getUTCFullYear() > year || date.getUTCFullYear() === year && date.getUTCMonth() > month) {
        cls.push('new');
      }

      if (this.focusDate && date.valueOf() === this.focusDate.valueOf()) cls.push('focused'); // Compare internal UTC date with UTC today, not local today

      if (this.o.todayHighlight && isUTCEquals(date, today)) {
        cls.push('today');
      }

      if (this.dates.contains(date) !== -1) cls.push('active');

      if (!this.dateWithinRange(date)) {
        cls.push('disabled');
      }

      if (this.dateIsDisabled(date)) {
        cls.push('disabled', 'disabled-date');
      } // Custom boilerplate for RES-5775


      if (this.dateIsArrival(date)) {
        cls.push('checkin');
      }

      if (this.dateIsDeparture(date)) {
        cls.push('checkout');
      } // End custom boilerplate


      if ($.inArray(date.getUTCDay(), this.o.daysOfWeekHighlighted) !== -1) {
        cls.push('highlighted');
      }

      if (this.range) {
        if (date > this.range[0] && date < this.range[this.range.length - 1]) {
          cls.push('range');
        }

        if ($.inArray(date.valueOf(), this.range) !== -1) {
          cls.push('selected');
        }

        if (date.valueOf() === this.range[0]) {
          cls.push('range-start');
        }

        if (date.valueOf() === this.range[this.range.length - 1]) {
          cls.push('range-end');
        }
      }

      return cls;
    },
    _fill_yearsView: function _fill_yearsView(selector, cssClass, factor, year, startYear, endYear, beforeFn) {
      var html = '';
      var step = factor / 10;
      var view = this.picker.find(selector);
      var startVal = Math.floor(year / factor) * factor;
      var endVal = startVal + step * 9;
      var focusedVal = Math.floor(this.viewDate.getFullYear() / step) * step;
      var selected = $.map(this.dates, function (d) {
        return Math.floor(d.getUTCFullYear() / step) * step;
      });
      var classes, tooltip, before;

      for (var currVal = startVal - step; currVal <= endVal + step; currVal += step) {
        classes = [cssClass];
        tooltip = null;

        if (currVal === startVal - step) {
          classes.push('old');
        } else if (currVal === endVal + step) {
          classes.push('new');
        }

        if ($.inArray(currVal, selected) !== -1) {
          classes.push('active');
        }

        if (currVal < startYear || currVal > endYear) {
          classes.push('disabled');
        }

        if (currVal === focusedVal) {
          classes.push('focused');
        }

        if (beforeFn !== $.noop) {
          before = beforeFn(new Date(currVal, 0, 1));

          if (before === undefined) {
            before = {};
          } else if (typeof before === 'boolean') {
            before = {
              enabled: before
            };
          } else if (typeof before === 'string') {
            before = {
              classes: before
            };
          }

          if (before.enabled === false) {
            classes.push('disabled');
          }

          if (before.classes) {
            classes = classes.concat(before.classes.split(/\s+/));
          }

          if (before.tooltip) {
            tooltip = before.tooltip;
          }
        }

        html += '<span class="' + classes.join(' ') + '"' + (tooltip ? ' title="' + tooltip + '"' : '') + '>' + currVal + '</span>';
      }

      view.find('.datepicker-switch').text(startVal + '-' + endVal);
      view.find('td').html(html);
    },
    fill: function fill() {
      var d = new Date(this.viewDate),
          year = d.getUTCFullYear(),
          month = d.getUTCMonth(),
          startYear = this.o.startDate !== -Infinity ? this.o.startDate.getUTCFullYear() : -Infinity,
          startMonth = this.o.startDate !== -Infinity ? this.o.startDate.getUTCMonth() : -Infinity,
          endYear = this.o.endDate !== Infinity ? this.o.endDate.getUTCFullYear() : Infinity,
          endMonth = this.o.endDate !== Infinity ? this.o.endDate.getUTCMonth() : Infinity,
          todaytxt = dates[this.o.language].today || dates['en'].today || '',
          cleartxt = dates[this.o.language].clear || dates['en'].clear || '',
          titleFormat = dates[this.o.language].titleFormat || dates['en'].titleFormat,
          tooltip,
          before;
      if (isNaN(year) || isNaN(month)) return;
      this.picker.find('.datepicker-days .datepicker-switch').text(DPGlobal.formatDate(d, titleFormat, this.o.language));
      this.picker.find('tfoot .today').text(todaytxt).css('display', this.o.todayBtn === true || this.o.todayBtn === 'linked' ? 'table-cell' : 'none');
      this.picker.find('tfoot .clear').text(cleartxt).css('display', this.o.clearBtn === true ? 'table-cell' : 'none');
      this.picker.find('thead .datepicker-title').text(this.o.title).css('display', typeof this.o.title === 'string' && this.o.title !== '' ? 'table-cell' : 'none');
      this.updateNavArrows();
      this.fillMonths();
      var prevMonth = UTCDate(year, month, 0),
          day = prevMonth.getUTCDate();
      prevMonth.setUTCDate(day - (prevMonth.getUTCDay() - this.o.weekStart + 7) % 7);
      var nextMonth = new Date(prevMonth);

      if (prevMonth.getUTCFullYear() < 100) {
        nextMonth.setUTCFullYear(prevMonth.getUTCFullYear());
      }

      nextMonth.setUTCDate(nextMonth.getUTCDate() + 42);
      nextMonth = nextMonth.valueOf();
      var html = [];
      var weekDay, clsName;

      while (prevMonth.valueOf() < nextMonth) {
        weekDay = prevMonth.getUTCDay();

        if (weekDay === this.o.weekStart) {
          html.push('<tr>');

          if (this.o.calendarWeeks) {
            // ISO 8601: First week contains first thursday.
            // ISO also states week starts on Monday, but we can be more abstract here.
            var // Start of current week: based on weekstart/current date
            ws = new Date(+prevMonth + (this.o.weekStart - weekDay - 7) % 7 * 864e5),
                // Thursday of this week
            th = new Date(Number(ws) + (7 + 4 - ws.getUTCDay()) % 7 * 864e5),
                // First Thursday of year, year from thursday
            yth = new Date(Number(yth = UTCDate(th.getUTCFullYear(), 0, 1)) + (7 + 4 - yth.getUTCDay()) % 7 * 864e5),
                // Calendar week: ms between thursdays, div ms per day, div 7 days
            calWeek = (th - yth) / 864e5 / 7 + 1;
            html.push('<td class="cw">' + calWeek + '</td>');
          }
        }

        clsName = this.getClassNames(prevMonth);
        clsName.push('day');
        var content = prevMonth.getUTCDate();

        if (this.o.beforeShowDay !== $.noop) {
          before = this.o.beforeShowDay(this._utc_to_local(prevMonth));
          if (before === undefined) before = {};else if (typeof before === 'boolean') before = {
            enabled: before
          };else if (typeof before === 'string') before = {
            classes: before
          };
          if (before.enabled === false) clsName.push('disabled');
          if (before.classes) clsName = clsName.concat(before.classes.split(/\s+/));
          if (before.tooltip) tooltip = before.tooltip;
          if (before.content) content = before.content;
        } //Check if uniqueSort exists (supported by jquery >=1.12 and >=2.2)
        //Fallback to unique function for older jquery versions


        if ($.isFunction($.uniqueSort)) {
          clsName = $.uniqueSort(clsName);
        } else {
          clsName = $.unique(clsName);
        }

        html.push('<td class="' + clsName.join(' ') + '"' + (tooltip ? ' title="' + tooltip + '"' : '') + ' data-date="' + prevMonth.getTime().toString() + '">' + content + '</td>');
        tooltip = null;

        if (weekDay === this.o.weekEnd) {
          html.push('</tr>');
        }

        prevMonth.setUTCDate(prevMonth.getUTCDate() + 1);
      }

      this.picker.find('.datepicker-days tbody').html(html.join(''));
      var monthsTitle = dates[this.o.language].monthsTitle || dates['en'].monthsTitle || 'Months';
      var months = this.picker.find('.datepicker-months').find('.datepicker-switch').text(this.o.maxViewMode < 2 ? monthsTitle : year).end().find('tbody span').removeClass('active');
      $.each(this.dates, function (i, d) {
        if (d.getUTCFullYear() === year) months.eq(d.getUTCMonth()).addClass('active');
      });

      if (year < startYear || year > endYear) {
        months.addClass('disabled');
      }

      if (year === startYear) {
        months.slice(0, startMonth).addClass('disabled');
      }

      if (year === endYear) {
        months.slice(endMonth + 1).addClass('disabled');
      }

      if (this.o.beforeShowMonth !== $.noop) {
        var that = this;
        $.each(months, function (i, month) {
          var moDate = new Date(year, i, 1);
          var before = that.o.beforeShowMonth(moDate);
          if (before === undefined) before = {};else if (typeof before === 'boolean') before = {
            enabled: before
          };else if (typeof before === 'string') before = {
            classes: before
          };
          if (before.enabled === false && !$(month).hasClass('disabled')) $(month).addClass('disabled');
          if (before.classes) $(month).addClass(before.classes);
          if (before.tooltip) $(month).prop('title', before.tooltip);
        });
      } // Generating decade/years picker


      this._fill_yearsView('.datepicker-years', 'year', 10, year, startYear, endYear, this.o.beforeShowYear); // Generating century/decades picker


      this._fill_yearsView('.datepicker-decades', 'decade', 100, year, startYear, endYear, this.o.beforeShowDecade); // Generating millennium/centuries picker


      this._fill_yearsView('.datepicker-centuries', 'century', 1000, year, startYear, endYear, this.o.beforeShowCentury);
    },
    updateNavArrows: function updateNavArrows() {
      if (!this._allow_update) return;
      var d = new Date(this.viewDate),
          year = d.getUTCFullYear(),
          month = d.getUTCMonth(),
          startYear = this.o.startDate !== -Infinity ? this.o.startDate.getUTCFullYear() : -Infinity,
          startMonth = this.o.startDate !== -Infinity ? this.o.startDate.getUTCMonth() : -Infinity,
          endYear = this.o.endDate !== Infinity ? this.o.endDate.getUTCFullYear() : Infinity,
          endMonth = this.o.endDate !== Infinity ? this.o.endDate.getUTCMonth() : Infinity,
          prevIsDisabled,
          nextIsDisabled,
          factor = 1;

      switch (this.viewMode) {
        case 0:
          prevIsDisabled = year <= startYear && month <= startMonth;
          nextIsDisabled = year >= endYear && month >= endMonth;
          break;

        case 4:
          factor *= 10;

        /* falls through */

        case 3:
          factor *= 10;

        /* falls through */

        case 2:
          factor *= 10;

        /* falls through */

        case 1:
          prevIsDisabled = Math.floor(year / factor) * factor <= startYear;
          nextIsDisabled = Math.floor(year / factor) * factor + factor >= endYear;
          break;
      }

      this.picker.find('.prev').toggleClass('disabled', prevIsDisabled);
      this.picker.find('.next').toggleClass('disabled', nextIsDisabled);
    },
    click: function click(e) {
      e.preventDefault();
      e.stopPropagation();
      var target, dir, day, year, month;
      target = $(e.target); // Clicked on the switch

      if (target.hasClass('datepicker-switch') && this.viewMode !== this.o.maxViewMode) {
        this.setViewMode(this.viewMode + 1);
      } // Clicked on today button


      if (target.hasClass('today') && !target.hasClass('day')) {
        this.setViewMode(0);

        this._setDate(UTCToday(), this.o.todayBtn === 'linked' ? null : 'view');
      } // Clicked on clear button


      if (target.hasClass('clear')) {
        this.clearDates();
      }

      if (!target.hasClass('disabled')) {
        // Clicked on a month, year, decade, century
        if (target.hasClass('month') || target.hasClass('year') || target.hasClass('decade') || target.hasClass('century')) {
          this.viewDate.setUTCDate(1);
          day = 1;

          if (this.viewMode === 1) {
            month = target.parent().find('span').index(target);
            year = this.viewDate.getUTCFullYear();
            this.viewDate.setUTCMonth(month);
          } else {
            month = 0;
            year = Number(target.text());
            this.viewDate.setUTCFullYear(year);
          }

          this._trigger(DPGlobal.viewModes[this.viewMode - 1].e, this.viewDate);

          if (this.viewMode === this.o.minViewMode) {
            this._setDate(UTCDate(year, month, day));
          } else {
            this.setViewMode(this.viewMode - 1);
            this.fill();
          }
        }
      }

      if (this.picker.is(':visible') && this._focused_from) {
        this._focused_from.focus();
      }

      delete this._focused_from;
    },
    dayCellClick: function dayCellClick(e) {
      var $target = $(e.currentTarget);
      var timestamp = $target.data('date');
      var date = new Date(timestamp);

      if (this.o.updateViewDate) {
        if (date.getUTCFullYear() !== this.viewDate.getUTCFullYear()) {
          this._trigger('changeYear', this.viewDate);
        }

        if (date.getUTCMonth() !== this.viewDate.getUTCMonth()) {
          this._trigger('changeMonth', this.viewDate);
        }
      }

      this._setDate(date);
    },
    // Clicked on prev or next
    navArrowsClick: function navArrowsClick(e) {
      var $target = $(e.currentTarget);
      var dir = $target.hasClass('prev') ? -1 : 1;

      if (this.viewMode !== 0) {
        dir *= DPGlobal.viewModes[this.viewMode].navStep * 12;
      }

      this.viewDate = this.moveMonth(this.viewDate, dir);

      this._trigger(DPGlobal.viewModes[this.viewMode].e, this.viewDate);

      this.fill();
    },
    _toggle_multidate: function _toggle_multidate(date) {
      var ix = this.dates.contains(date);

      if (!date) {
        this.dates.clear();
      }

      if (ix !== -1) {
        if (this.o.multidate === true || this.o.multidate > 1 || this.o.toggleActive) {
          this.dates.remove(ix);
        }
      } else if (this.o.multidate === false) {
        this.dates.clear();
        this.dates.push(date);
      } else {
        this.dates.push(date);
      }

      if (typeof this.o.multidate === 'number') while (this.dates.length > this.o.multidate) {
        this.dates.remove(0);
      }
    },
    _setDate: function _setDate(date, which) {
      if (!which || which === 'date') this._toggle_multidate(date && new Date(date));
      if (!which && this.o.updateViewDate || which === 'view') this.viewDate = date && new Date(date);
      this.fill();
      this.setValue();

      if (!which || which !== 'view') {
        this._trigger('changeDate');
      }

      this.inputField.trigger('change');

      if (this.o.autoclose && (!which || which === 'date')) {
        this.hide();
      }
    },
    moveDay: function moveDay(date, dir) {
      var newDate = new Date(date);
      newDate.setUTCDate(date.getUTCDate() + dir);
      return newDate;
    },
    moveWeek: function moveWeek(date, dir) {
      return this.moveDay(date, dir * 7);
    },
    moveMonth: function moveMonth(date, dir) {
      if (!isValidDate(date)) return this.o.defaultViewDate;
      if (!dir) return date;
      var new_date = new Date(date.valueOf()),
          day = new_date.getUTCDate(),
          month = new_date.getUTCMonth(),
          mag = Math.abs(dir),
          new_month,
          test;
      dir = dir > 0 ? 1 : -1;

      if (mag === 1) {
        test = dir === -1 ? // If going back one month, make sure month is not current month
        // (eg, Mar 31 -> Feb 31 == Feb 28, not Mar 02)
        function () {
          return new_date.getUTCMonth() === month;
        } : // If going forward one month, make sure month is as expected
        // (eg, Jan 31 -> Feb 31 == Feb 28, not Mar 02)
        function () {
          return new_date.getUTCMonth() !== new_month;
        };
        new_month = month + dir;
        new_date.setUTCMonth(new_month); // Dec -> Jan (12) or Jan -> Dec (-1) -- limit expected date to 0-11

        new_month = (new_month + 12) % 12;
      } else {
        // For magnitudes >1, move one month at a time...
        for (var i = 0; i < mag; i++) {
          // ...which might decrease the day (eg, Jan 31 to Feb 28, etc)...
          new_date = this.moveMonth(new_date, dir);
        } // ...then reset the day, keeping it in the new month


        new_month = new_date.getUTCMonth();
        new_date.setUTCDate(day);

        test = function test() {
          return new_month !== new_date.getUTCMonth();
        };
      } // Common date-resetting loop -- if date is beyond end of month, make it
      // end of month


      while (test()) {
        new_date.setUTCDate(--day);
        new_date.setUTCMonth(new_month);
      }

      return new_date;
    },
    moveYear: function moveYear(date, dir) {
      return this.moveMonth(date, dir * 12);
    },
    moveAvailableDate: function moveAvailableDate(date, dir, fn) {
      do {
        date = this[fn](date, dir);
        if (!this.dateWithinRange(date)) return false;
        fn = 'moveDay';
      } while (this.dateIsDisabled(date));

      return date;
    },
    weekOfDateIsDisabled: function weekOfDateIsDisabled(date) {
      return $.inArray(date.getUTCDay(), this.o.daysOfWeekDisabled) !== -1;
    },
    dateIsDisabled: function dateIsDisabled(date) {
      return this.weekOfDateIsDisabled(date) || $.grep(this.o.datesDisabled, function (d) {
        return isUTCEquals(date, d);
      }).length > 0;
    },
    // Custom boilerplate for RES-5775      
    dateIsArrival: function dateIsArrival(date) {
      return $.grep(this.o.datesArrival, function (d) {
        return isUTCEquals(date, d);
      }).length > 0;
    },
    dateIsDeparture: function dateIsDeparture(date) {
      return $.grep(this.o.datesDeparture, function (d) {
        return isUTCEquals(date, d);
      }).length > 0;
    },
    // End custom boilerplate
    dateWithinRange: function dateWithinRange(date) {
      return date >= this.o.startDate && date <= this.o.endDate;
    },
    keydown: function keydown(e) {
      if (!this.picker.is(':visible')) {
        if (e.keyCode === 40 || e.keyCode === 27) {
          // allow down to re-show picker
          this.show();
          e.stopPropagation();
        }

        return;
      }

      var dateChanged = false,
          dir,
          newViewDate,
          focusDate = this.focusDate || this.viewDate;

      switch (e.keyCode) {
        case 27:
          // escape
          if (this.focusDate) {
            this.focusDate = null;
            this.viewDate = this.dates.get(-1) || this.viewDate;
            this.fill();
          } else this.hide();

          e.preventDefault();
          e.stopPropagation();
          break;

        case 37: // left

        case 38: // up

        case 39: // right

        case 40:
          // down
          if (!this.o.keyboardNavigation || this.o.daysOfWeekDisabled.length === 7) break;
          dir = e.keyCode === 37 || e.keyCode === 38 ? -1 : 1;

          if (this.viewMode === 0) {
            if (e.ctrlKey) {
              newViewDate = this.moveAvailableDate(focusDate, dir, 'moveYear');
              if (newViewDate) this._trigger('changeYear', this.viewDate);
            } else if (e.shiftKey) {
              newViewDate = this.moveAvailableDate(focusDate, dir, 'moveMonth');
              if (newViewDate) this._trigger('changeMonth', this.viewDate);
            } else if (e.keyCode === 37 || e.keyCode === 39) {
              newViewDate = this.moveAvailableDate(focusDate, dir, 'moveDay');
            } else if (!this.weekOfDateIsDisabled(focusDate)) {
              newViewDate = this.moveAvailableDate(focusDate, dir, 'moveWeek');
            }
          } else if (this.viewMode === 1) {
            if (e.keyCode === 38 || e.keyCode === 40) {
              dir = dir * 4;
            }

            newViewDate = this.moveAvailableDate(focusDate, dir, 'moveMonth');
          } else if (this.viewMode === 2) {
            if (e.keyCode === 38 || e.keyCode === 40) {
              dir = dir * 4;
            }

            newViewDate = this.moveAvailableDate(focusDate, dir, 'moveYear');
          }

          if (newViewDate) {
            this.focusDate = this.viewDate = newViewDate;
            this.setValue();
            this.fill();
            e.preventDefault();
          }

          break;

        case 13:
          // enter
          if (!this.o.forceParse) break;
          focusDate = this.focusDate || this.dates.get(-1) || this.viewDate;

          if (this.o.keyboardNavigation) {
            this._toggle_multidate(focusDate);

            dateChanged = true;
          }

          this.focusDate = null;
          this.viewDate = this.dates.get(-1) || this.viewDate;
          this.setValue();
          this.fill();

          if (this.picker.is(':visible')) {
            e.preventDefault();
            e.stopPropagation();
            if (this.o.autoclose) this.hide();
          }

          break;

        case 9:
          // tab
          this.focusDate = null;
          this.viewDate = this.dates.get(-1) || this.viewDate;
          this.fill();
          this.hide();
          break;
      }

      if (dateChanged) {
        if (this.dates.length) this._trigger('changeDate');else this._trigger('clearDate');
        this.inputField.trigger('change');
      }
    },
    setViewMode: function setViewMode(viewMode) {
      this.viewMode = viewMode;
      this.picker.children('div').hide().filter('.datepicker-' + DPGlobal.viewModes[this.viewMode].clsName).show();
      this.updateNavArrows();

      this._trigger('changeViewMode', new Date(this.viewDate));
    }
  };

  var DateRangePicker = function DateRangePicker(element, options) {
    $.data(element, 'datepicker', this);
    this.element = $(element);
    this.inputs = $.map(options.inputs, function (i) {
      return i.jquery ? i[0] : i;
    });
    delete options.inputs;
    this.keepEmptyValues = options.keepEmptyValues;
    delete options.keepEmptyValues;
    datepickerPlugin.call($(this.inputs), options).on('changeDate', $.proxy(this.dateUpdated, this));
    this.pickers = $.map(this.inputs, function (i) {
      return $.data(i, 'datepicker');
    });
    this.updateDates();
  };

  DateRangePicker.prototype = {
    updateDates: function updateDates() {
      this.dates = $.map(this.pickers, function (i) {
        return i.getUTCDate();
      });
      this.updateRanges();
    },
    updateRanges: function updateRanges() {
      var range = $.map(this.dates, function (d) {
        return d.valueOf();
      });
      $.each(this.pickers, function (i, p) {
        p.setRange(range);
      });
    },
    dateUpdated: function dateUpdated(e) {
      // `this.updating` is a workaround for preventing infinite recursion
      // between `changeDate` triggering and `setUTCDate` calling.  Until
      // there is a better mechanism.
      if (this.updating) return;
      this.updating = true;
      var dp = $.data(e.target, 'datepicker');

      if (dp === undefined) {
        return;
      }

      var new_date = dp.getUTCDate(),
          keep_empty_values = this.keepEmptyValues,
          i = $.inArray(e.target, this.inputs),
          j = i - 1,
          k = i + 1,
          l = this.inputs.length;
      if (i === -1) return;
      $.each(this.pickers, function (i, p) {
        if (!p.getUTCDate() && (p === dp || !keep_empty_values)) p.setUTCDate(new_date);
      });

      if (new_date < this.dates[j]) {
        // Date being moved earlier/left
        while (j >= 0 && new_date < this.dates[j]) {
          this.pickers[j--].setUTCDate(new_date);
        }
      } else if (new_date > this.dates[k]) {
        // Date being moved later/right
        while (k < l && new_date > this.dates[k]) {
          this.pickers[k++].setUTCDate(new_date);
        }
      }

      this.updateDates();
      delete this.updating;
    },
    destroy: function destroy() {
      $.map(this.pickers, function (p) {
        p.destroy();
      });
      $(this.inputs).off('changeDate', this.dateUpdated);
      delete this.element.data().datepicker;
    },
    remove: alias('destroy', 'Method `remove` is deprecated and will be removed in version 2.0. Use `destroy` instead')
  };

  function opts_from_el(el, prefix) {
    // Derive options from element data-attrs
    var data = $(el).data(),
        out = {},
        inkey,
        replace = new RegExp('^' + prefix.toLowerCase() + '([A-Z])');
    prefix = new RegExp('^' + prefix.toLowerCase());

    function re_lower(_, a) {
      return a.toLowerCase();
    }

    for (var key in data) {
      if (prefix.test(key)) {
        inkey = key.replace(replace, re_lower);
        out[inkey] = data[key];
      }
    }

    return out;
  }

  function opts_from_locale(lang) {
    // Derive options from locale plugins
    var out = {}; // Check if "de-DE" style date is available, if not language should
    // fallback to 2 letter code eg "de"

    if (!dates[lang]) {
      lang = lang.split('-')[0];
      if (!dates[lang]) return;
    }

    var d = dates[lang];
    $.each(locale_opts, function (i, k) {
      if (k in d) out[k] = d[k];
    });
    return out;
  }

  var old = $.fn.datepicker;

  var datepickerPlugin = function datepickerPlugin(option) {
    var args = Array.apply(null, arguments);
    args.shift();
    var internal_return;
    this.each(function () {
      var $this = $(this),
          data = $this.data('datepicker'),
          options = _typeof(option) === 'object' && option;

      if (!data) {
        var elopts = opts_from_el(this, 'date'),
            // Preliminary otions
        xopts = $.extend({}, defaults, elopts, options),
            locopts = opts_from_locale(xopts.language),
            // Options priority: js args, data-attrs, locales, defaults
        opts = $.extend({}, defaults, locopts, elopts, options);

        if ($this.hasClass('input-daterange') || opts.inputs) {
          $.extend(opts, {
            inputs: opts.inputs || $this.find('input').toArray()
          });
          data = new DateRangePicker(this, opts);
        } else {
          data = new Datepicker(this, opts);
        }

        $this.data('datepicker', data);
      }

      if (typeof option === 'string' && typeof data[option] === 'function') {
        internal_return = data[option].apply(data, args);
      }
    });
    if (internal_return === undefined || internal_return instanceof Datepicker || internal_return instanceof DateRangePicker) return this;
    if (this.length > 1) throw new Error('Using only allowed for the collection of a single element (' + option + ' function)');else return internal_return;
  };

  $.fn.datepicker = datepickerPlugin;
  var defaults = $.fn.datepicker.defaults = {
    assumeNearbyYear: false,
    autoclose: false,
    beforeShowDay: $.noop,
    beforeShowMonth: $.noop,
    beforeShowYear: $.noop,
    beforeShowDecade: $.noop,
    beforeShowCentury: $.noop,
    calendarWeeks: false,
    clearBtn: false,
    toggleActive: false,
    daysOfWeekDisabled: [],
    daysOfWeekHighlighted: [],
    datesDisabled: [],
    endDate: Infinity,
    forceParse: true,
    format: 'mm/dd/yyyy',
    keepEmptyValues: false,
    keyboardNavigation: true,
    language: 'en',
    minViewMode: 0,
    maxViewMode: 4,
    multidate: false,
    multidateSeparator: ',',
    orientation: 'auto',
    rtl: false,
    startDate: -Infinity,
    startView: 0,
    todayBtn: false,
    todayHighlight: false,
    updateViewDate: true,
    weekStart: 0,
    disableTouchKeyboard: false,
    enableOnReadonly: true,
    showOnFocus: true,
    zIndexOffset: 10,
    container: 'body',
    immediateUpdates: false,
    title: '',
    templates: {
      leftArrow: '&#x00AB;',
      rightArrow: '&#x00BB;'
    },
    showWeekDays: true
  };
  var locale_opts = $.fn.datepicker.locale_opts = ['format', 'rtl', 'weekStart'];
  $.fn.datepicker.Constructor = Datepicker;
  var dates = $.fn.datepicker.dates = {
    en: {
      days: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
      daysShort: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
      daysMin: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
      months: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
      monthsShort: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
      today: 'Today',
      clear: 'Clear',
      titleFormat: 'MM yyyy'
    }
  };
  var DPGlobal = {
    viewModes: [{
      names: ['days', 'month'],
      clsName: 'days',
      e: 'changeMonth'
    }, {
      names: ['months', 'year'],
      clsName: 'months',
      e: 'changeYear',
      navStep: 1
    }, {
      names: ['years', 'decade'],
      clsName: 'years',
      e: 'changeDecade',
      navStep: 10
    }, {
      names: ['decades', 'century'],
      clsName: 'decades',
      e: 'changeCentury',
      navStep: 100
    }, {
      names: ['centuries', 'millennium'],
      clsName: 'centuries',
      e: 'changeMillennium',
      navStep: 1000
    }],
    validParts: /dd?|DD?|mm?|MM?|yy(?:yy)?/g,
    nonpunctuation: /[^ -\/:-@\u5e74\u6708\u65e5\[-`{-~\t\n\r]+/g,
    parseFormat: function parseFormat(format) {
      if (typeof format.toValue === 'function' && typeof format.toDisplay === 'function') return format; // IE treats \0 as a string end in inputs (truncating the value),
      // so it's a bad format delimiter, anyway

      var separators = format.replace(this.validParts, '\0').split('\0'),
          parts = format.match(this.validParts);

      if (!separators || !separators.length || !parts || parts.length === 0) {
        throw new Error('Invalid date format.');
      }

      return {
        separators: separators,
        parts: parts
      };
    },
    parseDate: function parseDate(date, format, language, assumeNearby) {
      if (!date) return undefined;
      if (date instanceof Date) return date;
      if (typeof format === 'string') format = DPGlobal.parseFormat(format);
      if (format.toValue) return format.toValue(date, format, language);
      var fn_map = {
        d: 'moveDay',
        m: 'moveMonth',
        w: 'moveWeek',
        y: 'moveYear'
      },
          dateAliases = {
        yesterday: '-1d',
        today: '+0d',
        tomorrow: '+1d'
      },
          parts,
          part,
          dir,
          i,
          fn;

      if (date in dateAliases) {
        date = dateAliases[date];
      }

      if (/^[\-+]\d+[dmwy]([\s,]+[\-+]\d+[dmwy])*$/i.test(date)) {
        parts = date.match(/([\-+]\d+)([dmwy])/gi);
        date = new Date();

        for (i = 0; i < parts.length; i++) {
          part = parts[i].match(/([\-+]\d+)([dmwy])/i);
          dir = Number(part[1]);
          fn = fn_map[part[2].toLowerCase()];
          date = Datepicker.prototype[fn](date, dir);
        }

        return Datepicker.prototype._zero_utc_time(date);
      }

      parts = date && date.match(this.nonpunctuation) || [];

      function applyNearbyYear(year, threshold) {
        if (threshold === true) threshold = 10; // if year is 2 digits or less, than the user most likely is trying to get a recent century

        if (year < 100) {
          year += 2000; // if the new year is more than threshold years in advance, use last century

          if (year > new Date().getFullYear() + threshold) {
            year -= 100;
          }
        }

        return year;
      }

      var parsed = {},
          setters_order = ['yyyy', 'yy', 'M', 'MM', 'm', 'mm', 'd', 'dd'],
          setters_map = {
        yyyy: function yyyy(d, v) {
          return d.setUTCFullYear(assumeNearby ? applyNearbyYear(v, assumeNearby) : v);
        },
        m: function m(d, v) {
          if (isNaN(d)) return d;
          v -= 1;

          while (v < 0) {
            v += 12;
          }

          v %= 12;
          d.setUTCMonth(v);

          while (d.getUTCMonth() !== v) {
            d.setUTCDate(d.getUTCDate() - 1);
          }

          return d;
        },
        d: function d(_d2, v) {
          return _d2.setUTCDate(v);
        }
      },
          val,
          filtered;
      setters_map['yy'] = setters_map['yyyy'];
      setters_map['M'] = setters_map['MM'] = setters_map['mm'] = setters_map['m'];
      setters_map['dd'] = setters_map['d'];
      date = UTCToday();
      var fparts = format.parts.slice(); // Remove noop parts

      if (parts.length !== fparts.length) {
        fparts = $(fparts).filter(function (i, p) {
          return $.inArray(p, setters_order) !== -1;
        }).toArray();
      } // Process remainder


      function match_part() {
        var m = this.slice(0, parts[i].length),
            p = parts[i].slice(0, m.length);
        return m.toLowerCase() === p.toLowerCase();
      }

      if (parts.length === fparts.length) {
        var cnt;

        for (i = 0, cnt = fparts.length; i < cnt; i++) {
          val = parseInt(parts[i], 10);
          part = fparts[i];

          if (isNaN(val)) {
            switch (part) {
              case 'MM':
                filtered = $(dates[language].months).filter(match_part);
                val = $.inArray(filtered[0], dates[language].months) + 1;
                break;

              case 'M':
                filtered = $(dates[language].monthsShort).filter(match_part);
                val = $.inArray(filtered[0], dates[language].monthsShort) + 1;
                break;
            }
          }

          parsed[part] = val;
        }

        var _date, s;

        for (i = 0; i < setters_order.length; i++) {
          s = setters_order[i];

          if (s in parsed && !isNaN(parsed[s])) {
            _date = new Date(date);
            setters_map[s](_date, parsed[s]);
            if (!isNaN(_date)) date = _date;
          }
        }
      }

      return date;
    },
    formatDate: function formatDate(date, format, language) {
      if (!date) return '';
      if (typeof format === 'string') format = DPGlobal.parseFormat(format);
      if (format.toDisplay) return format.toDisplay(date, format, language);
      var val = {
        d: date.getUTCDate(),
        D: dates[language].daysShort[date.getUTCDay()],
        DD: dates[language].days[date.getUTCDay()],
        m: date.getUTCMonth() + 1,
        M: dates[language].monthsShort[date.getUTCMonth()],
        MM: dates[language].months[date.getUTCMonth()],
        yy: date.getUTCFullYear().toString().substring(2),
        yyyy: date.getUTCFullYear()
      };
      val.dd = (val.d < 10 ? '0' : '') + val.d;
      val.mm = (val.m < 10 ? '0' : '') + val.m;
      date = [];
      var seps = $.extend([], format.separators);

      for (var i = 0, cnt = format.parts.length; i <= cnt; i++) {
        if (seps.length) date.push(seps.shift());
        date.push(val[format.parts[i]]);
      }

      return date.join('');
    },
    headTemplate: '<thead>' + '<tr>' + '<th colspan="7" class="datepicker-title"></th>' + '</tr>' + '<tr>' + '<th class="prev">' + defaults.templates.leftArrow + '</th>' + '<th colspan="5" class="datepicker-switch"></th>' + '<th class="next">' + defaults.templates.rightArrow + '</th>' + '</tr>' + '</thead>',
    contTemplate: '<tbody><tr><td colspan="7"></td></tr></tbody>',
    footTemplate: '<tfoot>' + '<tr>' + '<th colspan="7" class="today"></th>' + '</tr>' + '<tr>' + '<th colspan="7" class="clear"></th>' + '</tr>' + '</tfoot>'
  };
  DPGlobal.template = '<div class="datepicker">' + '<div class="datepicker-days">' + '<table class="table-condensed">' + DPGlobal.headTemplate + '<tbody></tbody>' + DPGlobal.footTemplate + '</table>' + '</div>' + '<div class="datepicker-months">' + '<table class="table-condensed">' + DPGlobal.headTemplate + DPGlobal.contTemplate + DPGlobal.footTemplate + '</table>' + '</div>' + '<div class="datepicker-years">' + '<table class="table-condensed">' + DPGlobal.headTemplate + DPGlobal.contTemplate + DPGlobal.footTemplate + '</table>' + '</div>' + '<div class="datepicker-decades">' + '<table class="table-condensed">' + DPGlobal.headTemplate + DPGlobal.contTemplate + DPGlobal.footTemplate + '</table>' + '</div>' + '<div class="datepicker-centuries">' + '<table class="table-condensed">' + DPGlobal.headTemplate + DPGlobal.contTemplate + DPGlobal.footTemplate + '</table>' + '</div>' + '</div>';
  $.fn.datepicker.DPGlobal = DPGlobal;
  /* DATEPICKER NO CONFLICT
   * =================== */

  $.fn.datepicker.noConflict = function () {
    $.fn.datepicker = old;
    return this;
  };
  /* DATEPICKER VERSION
   * =================== */


  $.fn.datepicker.version = '1.7.1';

  $.fn.datepicker.deprecated = function (msg) {
    var console = window.console;

    if (console && console.warn) {
      console.warn('DEPRECATED: ' + msg);
    }
  };
  /* DATEPICKER DATA-API
   * ================== */


  $(document).on('focus.datepicker.data-api click.datepicker.data-api', '[data-provide="datepicker"]', function (e) {
    var $this = $(this);
    if ($this.data('datepicker')) return;
    e.preventDefault(); // component click requires us to explicitly show it

    datepickerPlugin.call($this, 'show');
  });
  $(function () {
    datepickerPlugin.call($('[data-provide="datepicker-inline"]'));
  });
});
/*! echo-js v1.7.3 | (c) 2016 @toddmotto | https://github.com/toddmotto/echo */


(function (root, factory) {
  if (typeof define === 'function' && define.amd) {
    define(function () {
      return factory(root);
    });
  } else if ((typeof exports === "undefined" ? "undefined" : _typeof(exports)) === 'object') {
    module.exports = factory;
  } else {
    root.echo = factory(root);
  }
})(window, function (root) {
  'use strict';

  var echo = {};

  var callback = function callback() {};

  var offset, poll, delay, useDebounce, unload;

  var isHidden = function isHidden(element) {
    return element.offsetParent === null;
  };

  var inView = function inView(element, view) {
    if (isHidden(element)) {
      return false;
    }

    var box = element.getBoundingClientRect();
    return box.right >= view.l && box.bottom >= view.t && box.left <= view.r && box.top <= view.b;
  };

  var debounceOrThrottle = function debounceOrThrottle() {
    if (!useDebounce && !!poll) {
      return;
    }

    clearTimeout(poll);
    poll = setTimeout(function () {
      echo.render();
      poll = null;
    }, delay);
  };

  echo.init = function (opts) {
    opts = opts || {};
    var offsetAll = opts.offset || 0;
    var offsetVertical = opts.offsetVertical || offsetAll;
    var offsetHorizontal = opts.offsetHorizontal || offsetAll;

    var optionToInt = function optionToInt(opt, fallback) {
      return parseInt(opt || fallback, 10);
    };

    offset = {
      t: optionToInt(opts.offsetTop, offsetVertical),
      b: optionToInt(opts.offsetBottom, offsetVertical),
      l: optionToInt(opts.offsetLeft, offsetHorizontal),
      r: optionToInt(opts.offsetRight, offsetHorizontal)
    };
    delay = optionToInt(opts.throttle, 250);
    useDebounce = opts.debounce !== false;
    unload = !!opts.unload;
    callback = opts.callback || callback;
    echo.render();

    if (document.addEventListener) {
      root.addEventListener('scroll', debounceOrThrottle, false);
      root.addEventListener('load', debounceOrThrottle, false);
    } else {
      root.attachEvent('onscroll', debounceOrThrottle);
      root.attachEvent('onload', debounceOrThrottle);
    }
  };

  echo.render = function (context) {
    var nodes = (context || document).querySelectorAll('[data-echo], [data-echo-background]');
    var length = nodes.length;
    var src, elem;
    var view = {
      l: 0 - offset.l,
      t: 0 - offset.t,
      b: (root.innerHeight || document.documentElement.clientHeight) + offset.b,
      r: (root.innerWidth || document.documentElement.clientWidth) + offset.r
    };

    for (var i = 0; i < length; i++) {
      elem = nodes[i];

      if (inView(elem, view)) {
        if (unload) {
          elem.setAttribute('data-echo-placeholder', elem.src);
        }

        if (elem.getAttribute('data-echo-background') !== null) {
          elem.style.backgroundImage = 'url(' + elem.getAttribute('data-echo-background') + ')';
        } else if (elem.src !== (src = elem.getAttribute('data-echo'))) {
          elem.src = src;
        }

        if (!unload) {
          elem.removeAttribute('data-echo');
          elem.removeAttribute('data-echo-background');
        }

        callback(elem, 'load');
      } else if (unload && !!(src = elem.getAttribute('data-echo-placeholder'))) {
        if (elem.getAttribute('data-echo-background') !== null) {
          elem.style.backgroundImage = 'url(' + src + ')';
        } else {
          elem.src = src;
        }

        elem.removeAttribute('data-echo-placeholder');
        callback(elem, 'unload');
      }
    }

    if (!length) {
      echo.detach();
    }
  };

  echo.detach = function () {
    if (document.removeEventListener) {
      root.removeEventListener('scroll', debounceOrThrottle);
    } else {
      root.detachEvent('onscroll', debounceOrThrottle);
    }

    clearTimeout(poll);
  };

  return echo;
});

(function (LIVEREZ, window, $, undefined) {
  var StripCalendar = function StripCalendar(starDate, endDate, datesArr) {
    var self = this;
    this.startDate = new Date(starDate);
    this.endDate = new Date(endDate);
    this.numberOfDays = getDateDiff(self.startDate, self.endDate);
    this.data = datesArr;
    this.startingMonth = this.startDate.getMonth();
    this.stoppingMonth = this.endDate.getMonth();
    this.displayDates = getDates(self.startDate, self.endDate);
  };

  var _isLeapYear = function _isLeapYear(year) {
    return year % 4 === 0 && year % 100 !== 0 || year % 400 === 0;
  };

  var _getDaysInMonth = function _getDaysInMonth(year, month) {
    return [31, _isLeapYear(year) ? 29 : 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31][month];
  };

  var _dates = {
    days: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
    daysShort: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
    daysSuperShort: ['S', 'M', 'T', 'W', 'T', 'F', 'S'],
    months: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
    monthsShort: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
  };

  StripCalendar.prototype.generateHtml = function (callback) {
    var container = $('<div />').addClass('table-responsive');
    var table = $('<table />').addClass('lr-strip-calendar');

    var _daysInEachMonth = getDaysInEachMonth(this.displayDates);

    var headingRow = $('<tr />').addClass('lr-strip-calendar--heading-row');
    var weekdayRow = $('<tr />').addClass('lr-strip-calendar--weekday-row');
    var dateRow = $('<tr />').addClass('lr-strip-calendar--date-row'); // add months to heading row

    for (var key in _daysInEachMonth) {
      var monthIndex = key.split('_')[1];
      var month = $('<td />').text(_dates.monthsShort[monthIndex]).attr('colspan', _daysInEachMonth[key]);
      headingRow.append(month);
    } // add days to the date row


    this.displayDates.forEach(function (item) {
      var weekday = $('<td />').text(_dates.daysSuperShort[item.getDay()]);
      weekday.addClass('lr-strip-calendar--weekday');
      weekdayRow.append(weekday);
      var day = $('<td />').text(item.getDate());
      day.addClass('lr-strip-calendar--day ' + formatDate(item));
      dateRow.append(day);
    }); // paint the days with the correct classes

    this.data.forEach(function (calendarDay) {
      var current = dateRow.children('.' + formatDate(calendarDay.date));

      if (calendarDay.checkIn) {
        current.addClass('checkin');
      }

      if (calendarDay.checkOut) {
        current.addClass('checkout');
      }

      if (calendarDay.occupied) {
        current.addClass('occupied');
      }
    });
    table.append(headingRow);
    table.append(weekdayRow);
    table.append(dateRow);
    container.append(table);
    callback(container.html());
  };

  return LIVEREZ.StripCalendar = StripCalendar;

  function formatDate(date) {
    return date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate();
  }

  function getDateDiff(date1, date2) {
    return Math.abs((date1.getTime() - date2.getTime()) / (1000 * 60 * 60 * 24));
  }

  function getDates(d1, d2) {
    var dates = [];
    var currentDate = new Date(d1);
    var endDate = new Date(d2);

    while (currentDate <= endDate) {
      dates.push(new Date(currentDate));
      currentDate.setDate(currentDate.getDate() + 1);
    }

    return dates;
  }

  function getDaysInEachMonth(datesArr) {
    var obj = {};
    datesArr.forEach(function (item) {
      var _month = item.getMonth();

      var _year = item.getFullYear();

      var key = _year + '_' + _month;

      if (!obj.hasOwnProperty(key)) {
        obj[key] = 1;
      } else {
        obj[key] = obj[key] + 1;
      }
    });
    return obj;
  }
})(window.LIVEREZ = window.LIVEREZ || {}, window, lrjQ);